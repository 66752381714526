import React from 'react';
import { useLocale } from '@/hooks/use-locale';
import { FieldGroup } from '../form';
import { usePaymentMethodContext } from '@/hooks/use-payment-method-context';
import { AcceptanceMark } from './payment-methods/acceptance-mark';

export function PaymentMethodSummary ({
  onClearPaymentMethod
}: {
  onClearPaymentMethod: Function;
}) {
  const { t } = useLocale();
  const {
    paymentMethod,
    setPaymentMethod,
    usedExpressCheckout
  } = usePaymentMethodContext();

  if (!usedExpressCheckout) return;
  if (!paymentMethod) return;

  return (
    <FieldGroup title={t('payment-info')}>
      <div className="col-span-2">
        <div className="bg-checkout-accent p-4 rounded-lg flex space-x-3">
          <AcceptanceMark type={paymentMethod} />
          <span className="text-gray-500">
            {t('payment-method-selected', {
              paymentMethod: t(`payment-method.${paymentMethod.type.replace(/_/g, '-')}.name`)
            })}
          </span>
        </div>
        <p className="mt-4">
          <a
            href="#"
            className="font-medium"
            onClick={event => {
              event.preventDefault();
              setPaymentMethod(undefined);
              onClearPaymentMethod();
            }}
          >
            {t('change-payment-method')}
          </a>
        </p>
      </div>
    </FieldGroup>
  );
}
