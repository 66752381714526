import React, { PropsWithChildren } from 'react';
import { Price, PriceObject } from '@/components/price';
import { useCartContext } from '@/hooks/use-cart-context';

export function SubtotalLine ({
  children,
  className = '',
  price,
  priceClassName,
  subtractive = false
}: {
  className?: string;
  price: PriceObject;
  priceClassName?: string;
  subtractive?: boolean;
} & PropsWithChildren) {
  const { priceLoading } = useCartContext();

  return (
    <div className={`grid grid-cols-2 items-start my-2 ${className}`}>
      {children}
      <div className="text-right">
        {
          priceLoading
            ? (
              <div className="w-10 h-4 inline-block rounded bg-gray-200 dark:bg-gray-600 animate-pulse">
                &nbsp;
              </div>
            ) : (
              <span className={priceClassName}>
                {subtractive && '-'}<Price {...price} />
              </span>
            )
        }
      </div>
    </div>
  );
}
