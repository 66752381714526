import { useEffect } from 'react';

import {
  localeForTag,
  preferredLocaleTag,
  translatorFor
} from '../services/locale';
import { useCheckoutSession } from './use-checkout-session';

export function useLocale () {
  const [{ locale: localeTag }, updateCheckoutSession] = useCheckoutSession();
  const locale = localeForTag(localeTag);
  const setLocaleByTag = (tag: string) => updateCheckoutSession({ locale: localeForTag(tag).baseName });

  useEffect(() => {
    const onLanguageChange = () => setLocaleByTag(preferredLocaleTag());
    window.addEventListener('languagechange', onLanguageChange);
    return () => window.removeEventListener('languagechange', onLanguageChange);
  }, []);

  return {
    locale,
    t: translatorFor(locale)
  };
}
