import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';

import { ENVIRONMENTS, EnvironmentContext } from './environment';
import { useLocalStorage } from '../hooks/use-storage';
import { version } from '../../package.json';

const ActionButton = ({ action, children }: { action: Function; } & PropsWithChildren) => (
  <button
    className="
      appearance-none w-200 rounded-md py-2 px-2
      focus:ring-2 text-sm shadow-sm
      bg-blue-500 hover:bg-blue-400 active:bg-blue-300
    "
    onClick={() => action()}
  >
    {children}
  </button>
);

const getCheckoutSessionFromStorage = () => JSON.stringify(JSON.parse(
  sessionStorage.getItem('__recurly__.checkoutSession') || '{}'
), null, 2);

export function Development () {
  const [sessionText, setSessionText] = useState(getCheckoutSessionFromStorage());
  const [environment, setEnvironment] = useContext(EnvironmentContext);
  const [api, setApi] = useLocalStorage('recurlyApiUrl', environment.api);
  const [sessionWindowOpen, setSessionWindowOpen] = useState(false);
  const [show, setShow] = useState(false);

  const SHOW_PANEL_IN = [ENVIRONMENTS.DEVELOPMENT, ENVIRONMENTS.QA];

  if (!SHOW_PANEL_IN.includes(environment.env)) {
    return <></>;
  }

  const clearSession = () => {
    sessionStorage.removeItem('__recurly__.checkoutSession');
    sessionStorage.removeItem('__recurly__.checkoutConfigurationId');
    window.location.reload();
  };

  const fill = () => {
    let delay = 0;
    [
      ['[data-recurly=email_address]', 'test@test.com'],
      ['[data-recurly=first_name]', 'Testing'],
      ['[data-recurly=last_name]', 'User'],
      ['[data-recurly=company]', 'Company, inc.'],

      ['[data-recurly="shipping[address][first_name]"]', 'Testing'],
      ['[data-recurly="shipping[address][last_name]"]', 'User'],
      ['[data-recurly="shipping[address][company]"]', 'Business co.'],
      ['[data-recurly="shipping[address][address1]"]', '10 Shipping Way'],
      ['[data-recurly="shipping[address][address2]"]', 'Unit 0'],
      ['[data-recurly="shipping[address][city]"]', 'San Francisco'],
      ['[data-recurly="shipping[address][state]"]', 'CA'],
      ['[data-recurly="shipping[address][postal_code]"]', '94110'],
      ['[data-recurly="shipping[address][phone]"]', '4151002000'],

      ['[data-recurly=address1', '10 Billing St.'],
      ['[data-recurly=address2', 'Apt. 1'],
      ['[data-recurly=city', 'San Francisco'],
      ['[data-recurly=state]', 'CA'],
      ['[data-recurly=postal_code]', '94114'],
      ['[data-recurly=phone]', '5042003000']
    ].forEach(async ([sel, val]) => {
      const el = document.querySelector(sel) as HTMLInputElement;
      if (!el) return;

      while (el.value !== val) {
        // @ts-ignore
        Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          'value'
        ).set.call(el, val);
        await new Promise<void>(resolve => setTimeout(() => {
          el?.dispatchEvent(new Event('input', { bubbles: true }));
          resolve();
        }, (delay = delay + 10)));
      }
      console.log('filled', el.dataset.recurly, el.value);
    });
  };

  useEffect(() => {
    if (environment.env === ENVIRONMENTS.DEVELOPMENT) {
      new EventSource('/esbuild').addEventListener('change', () => {
        console.log('reloading...');
        location.reload();
      });
    }
  }, []);

  useEffect(() => {
    setEnvironment({ ...environment, api, loading: false });
  }, [api]);

  return (
    <>
      <button className="fixed bottom-2 right-2 bg-slate-100 rounded-full w-14 h-14 z-30 content-center shadow-xl text-3xl appearance-none" type="button" onClick={() => setShow(!show)}>🔧</button>
      <section className={`text-white bg-blue-700 bottom-0 p-2 w-full fixed flex items-center gap-2 flex-wrap z-20 ${!show && 'hidden'}`}>
        <input
          type="string"
          onChange={e => setApi(e.target.value)}
          defaultValue={api}
          placeholder="api url"
          className="focus:ring-2 focus:ring-blue-300 focus:outline-none appearance-none w-1/4 rounded-md py-2 px-2 ring-1 text-sm shadow-sm min-w-fit"
        />

        <ActionButton action={() => setSessionWindowOpen(!sessionWindowOpen)}>
          show session
        </ActionButton>

        <ActionButton action={() => clearSession()}>
          clear session
        </ActionButton>

        <ActionButton action={() => fill()}>
          fill
        </ActionButton>

        <div className="text-right flex-grow mr-16">
          checkout v{version}
        </div>

        <div
          className="w-full overflow-scroll p-1 bg-blue-700 top-0 left-0 bottom-12 fixed"
          style={{ display: sessionWindowOpen ? 'inline-block' : 'none' }}
        >
          <button
            className="text-3xl"
            onClick={() => {
              setSessionText(getCheckoutSessionFromStorage());
            }}
          >⟳</button>
          <textarea
            className="w-full h-full bg-transparent font-mono"
            onChange={event => {
              sessionStorage.setItem('__recurly__.checkoutSession', event.target.value);
              setSessionText(event.target.value);
            }}
            value={sessionText}
          />
        </div>
      </section>
    </>
  );
}
