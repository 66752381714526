import React, { Dispatch, PropsWithChildren, SetStateAction, createContext, useEffect, useMemo, useState } from 'react';
import { SetCheckoutPricing, Subscription, useCheckoutPricing } from '@recurly/react-recurly';
import { Address, CheckoutPrice, CheckoutPricingInstance } from '@recurly/recurly-js';
import { CheckoutAddress } from '@/types/checkout-address';
import { useCheckoutSession } from '@/hooks/use-checkout-session';
import { browserLocale } from '@/services/locale';

export const CART_EMPTINESS_STATE = {
  NO_ITEMS: 0,
  ZERO_ITEMS: 1,
  NOT_EMPTY: 2
};

export type CartContextProps = {
  billingAddress?: Address;
  setBillingAddress: Dispatch<SetStateAction<Address>>;
  cartEmptiness?: number;
  price: CheckoutPrice;
  priceLoading: boolean;
  pricing?: CheckoutPricingInstance;
  requiresBillingInfo: boolean;
  setCartEmptiness: Dispatch<SetStateAction<number | undefined>>;
  setCheckoutPricing: SetCheckoutPricing;
  setRequiresBillingInfo: Dispatch<SetStateAction<boolean>>;
  shippingAddress?: Address;
  setShippingAddress: Dispatch<SetStateAction<Address>>;
  soleTrialSubscription?: Subscription;
  setSoleTrialSubscription: Dispatch<SetStateAction<Subscription | undefined>>;
}

export const CartContext = createContext<CartContextProps | null>(null);

export function CartContextProvider ({ children }: PropsWithChildren): React.JSX.Element {
  const preferredCountry = browserLocale().region;
  const [billingAddress, setBillingAddress] = useState<CheckoutAddress>({ country: preferredCountry });
  const [shippingAddress, setShippingAddress] = useState<CheckoutAddress>();
  const [cartEmptiness, setCartEmptiness] = useState<number>();
  const [requiresBillingInfo, setRequiresBillingInfo] = useState(true);
  const [soleTrialSubscription, setSoleTrialSubscription] = useState<Subscription | undefined>(undefined);

  const [checkoutSession, _setCheckoutSession] = useCheckoutSession();
  const {
    cart: { currency },
  } = checkoutSession;

  const [
    { price: checkoutPricingPrice, loading: checkoutPricingPriceLoading },
    setCheckoutPricing,
    pricing
  ] = useCheckoutPricing({});
  const price = useMemo(() => checkoutPricingPrice, [JSON.stringify(checkoutPricingPrice)]);
  const priceLoading = (
    checkoutPricingPriceLoading
    && Object.keys(price).length === 0
    || price.currency.code !== currency.code
  );

  useEffect(() => {
    if (priceLoading) return;
    if (pricing.items.subscriptions.length === 1 && pricing.items.subscriptions[0].items.plan.trial) {
      setSoleTrialSubscription(pricing.items.subscriptions[0]);
    }
  }, [priceLoading, pricing]);

  useEffect(() => {
    if (!soleTrialSubscription) return;
    setRequiresBillingInfo(!!soleTrialSubscription?.items.plan.trial.trial_requires_billing_info);
  }, [soleTrialSubscription]);

  return (
    <CartContext.Provider value={{
      cartEmptiness,
      setCartEmptiness,
      billingAddress,
      setBillingAddress,
      price,
      priceLoading,
      pricing,
      requiresBillingInfo,
      setRequiresBillingInfo,
      setCheckoutPricing,
      shippingAddress,
      setShippingAddress,
      soleTrialSubscription,
      setSoleTrialSubscription,
    }}>
      {children}
    </CartContext.Provider>
  );
}
