import React, { useEffect, useMemo, useState } from 'react';
import { RecurlyError, TokenPayload } from '@recurly/recurly-js';
import { useRecurly } from '@recurly/react-recurly';

import { AmazonPayLogoImage } from '../../images/amazon-pay-logo';
import { Button } from '../../form';
import { Currency } from '../../price';
import { PaymentGateway, PaymentMethod } from '@/services/session';
import { useCheckoutSession } from '@/hooks/use-checkout-session';
import { usePaymentMethodContext } from '@/hooks/use-payment-method-context';
import { ExpressCheckoutProps } from '../express-checkout';

const GATEWAY_TYPES = [
  { type: 'amazon_v2', region: 'us' },
  { type: 'amazon_v2_eu', region: 'eu' },
  { type: 'amazon_v2_uk', region: 'uk' }
];

const isCompatibleGateway = ({ type }: PaymentGateway) => GATEWAY_TYPES.some(t => t.type === type);
const regionForGateway = ({ type }: PaymentGateway) => GATEWAY_TYPES.find(t => t.type === type)?.region;
const gatewayForCurrency = ({
  gateways
}: PaymentMethod, {
  code: sessionCurrencyCode
}: Currency) => gateways.find(
  ({ currencies }: PaymentGateway) => currencies.some(({ code }) => code === sessionCurrencyCode)
);

export function AmazonPayButton ({ onError, onToken }: ExpressCheckoutProps) {
  const {
    paymentMethodForType,
    setPaymentMethod
  } = usePaymentMethodContext();
  const [{ cart: { currency } }] = useCheckoutSession();

  const amazonPaymentMethod = paymentMethodForType('amazon') as PaymentMethod;
  const gateway = gatewayForCurrency(amazonPaymentMethod, currency);

  if (!gateway) return;
  if (!isCompatibleGateway(gateway)) return;

  const [canSubmit, setCanSubmit] = useState(true);
  const [show, setShow] = useState(false);
  const recurly = useRecurly();

  const amazonPay = useMemo(() => recurly.AmazonPay({
    region: regionForGateway(gateway),
    gatewayCode: gateway.code
  }), []);

  useEffect(() => {
    amazonPay.on('ready', () => setShow(true));
    amazonPay.on('error', (error: RecurlyError) => {
      setCanSubmit(true);
      onError(error);
    });
    amazonPay.on('cancel', () => {
      setCanSubmit(true);
    });
    amazonPay.on('token', (token: TokenPayload) => {
      setPaymentMethod(amazonPaymentMethod);
      onToken(token);
      setCanSubmit(true);
    });
  }, [amazonPay]);

  if (!show) return;

  return (
    <Button
      className="
        bg-[#FFD814]
        min-w-full min-h-12 py-2 px-4 mb-2
        rounded font-bold border-transparent
        hover:brightness-95 col-span-2
      "
      onClick={(event) => {
        event.preventDefault();
        setCanSubmit(false);
        amazonPay.attach();
      }}
      processing={!canSubmit}
    >
      <AmazonPayLogoImage className="mx-auto" />
    </Button>
  );
}
