import { useContext } from 'react';
import { CartContext, CartContextProps } from '@/contexts/cart-context';

export function useCartContext (): CartContextProps {
  const context = useContext(CartContext);

  if (context === undefined) {
    throw new Error('useCartContext must be used within a CartContextProvider');
  }

  if (context === null) {
    throw new Error('CartContext has not been Provided');
  }

  return context;
}
