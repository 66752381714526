import React, { useContext } from 'react';
import { twMerge } from 'tailwind-merge';

import { FLAGS } from '@/services/session';
import { useCheckoutSession } from '@/hooks/use-checkout-session';
import { useLocale } from '@/hooks/use-locale';
import { CART_EMPTINESS_STATE } from '@/contexts/cart-context';
import { PromoCodes } from './cart/promo-codes';
import { DiscountLine } from './cart/discount-line';
import { SubtotalLine } from './cart/subtotal-line';
import { TotalHero } from './cart/total-hero';
import { CurrencySelector } from './cart/currency-selector';
import { CartItems } from './cart/cart-items';
import { CartInternalContext } from '@/contexts/cart-internal-context';
import { useCartContext } from '@/hooks/use-cart-context';

export function Cart () {
  const [checkoutSession] = useCheckoutSession();
  const {
    cart: {
      currencies
    },
    flags
  } = checkoutSession;

  const {
    cartEmptiness,
    price,
    priceLoading,
    pricing,
  } = useCartContext();
  const { t } = useLocale();

  const { open, setOpen } = useContext(CartInternalContext);

  const subtotalBeforeDiscounts = (
    priceLoading || cartEmptiness === CART_EMPTINESS_STATE.NO_ITEMS
  ) ? '' : (Number(price.now.subtotal) + Number(price.now.discount)).toFixed(2);

  if (cartEmptiness === CART_EMPTINESS_STATE.NO_ITEMS) {
    return <></>;
  }

  return (
    <div
      className={twMerge(
        'mx-auto overflow-hidden max-w-lg mb-8 p-5 rounded-lg bg-checkout-accent',
        !open && 'h-16',
        'lg:w-96 lg:h-auto'
      )}
      onClick={() => !open && setOpen(true)}
    >
      <TotalHero />

      {(currencies.length > 1 && !flags.includes(FLAGS.DISABLE_CURRENCY_SELECTION)) && (
        <CurrencySelector />
      )}

      {
        priceLoading
          ? (
            <div className="grid grid-cols-4 gap-4 animate-pulse mt-12 mb-4">
              <div className="h-4 rounded bg-gray-200 dark:bg-gray-600 col-span-3"></div>
              <div className="h-4 rounded bg-gray-200 dark:bg-gray-600 col-span-1"></div>
            </div>
          )
          : (
            <CartItems />
          )
      }

      <div className="pt-6">
        <SubtotalLine
          price={{ currency: price.currency, amount: subtotalBeforeDiscounts }}
        >
          {t('cart.subtotal-line.subtotal')}
        </SubtotalLine>

        {flags.includes(FLAGS.ACCEPT_COUPONS) && (
          <PromoCodes />
        )}

        {(pricing?.items?.coupon || pricing?.items?.giftCard) && (
          <DiscountLine />
        )}

        {Number(price?.now?.taxes) > 0 && (
          <SubtotalLine price={{ currency: price.currency, amount: price?.now?.taxes }}>
            {t('cart.subtotal-line.tax-label')}
          </SubtotalLine>
        )}

        <SubtotalLine
          price={{ currency: price.currency, amount: price?.now?.total }}
          priceClassName="font-medium"
        >
          <span className="font-medium">{t('cart.total-line.label')}</span>
          {/*<p className="pt-2 text-sm font-light text-checkout-subtle">
            {priceLoading || (`
              And then <Price currency={price.currency} amount={price.next.subtotal} />
              ${price.next.taxes > 0 ? '+ tax' : ''}
            `)}
          </p>*/}
        </SubtotalLine>

        <p className="text-xs mt-6 text-checkout-subtle">
          {t(
            'powered-by',
            {
              recurly: <a href="https://recurly.com" className="text-checkout-subtle" target="_blank">Recurly</a>
            }
          )}
        </p>
      </div>
    </div>
  );
}
