import React, { useEffect } from 'react';
import hexRgb from 'hex-rgb';

import { useCheckoutSession } from '../hooks/use-checkout-session';
import { CssColor } from '../types/html';

const BRAND_CONTRAST_LIGHT = '--brand-contrast-light-rgb';
const BRAND_CONTRAST_DARK = '--brand-contrast-dark-rgb';

const getCssProperty = (prop: string) => getComputedStyle(document.documentElement).getPropertyValue(prop);
const rgbFor = (hex: string) => Object.values(hexRgb(hex)).toString();
const lumaFor = (hex: string) => {
  const { red, green, blue } = hexRgb(hex);
  return (0.212 * red + 0.701 * green + 0.087 * blue) / 255;
};

const contrastFor = (hex: string) => getCssProperty(lumaFor(hex) > 0.5 ? BRAND_CONTRAST_DARK : BRAND_CONTRAST_LIGHT);
const themeRgbGetter = (variable: string) => (): CssColor => `rgb(${getCssProperty(variable)})`;

export const currentForegroundRgb = themeRgbGetter('--foreground-rgb');
export const currentBrandContrastRgb = themeRgbGetter('--brand-contrast-rgb');

export function Theme () {
  const [{
    primaryColor,
    prefersColorScheme
  }] = useCheckoutSession();

  useEffect(() => {
    if (!primaryColor) return;
    document.documentElement.style.setProperty('--brand-rgb', rgbFor(primaryColor));
    document.documentElement.style.setProperty('--brand-contrast-rgb', contrastFor(primaryColor));
  }, [primaryColor]);

  useEffect(() => {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.add('light');
    }

    if (prefersColorScheme === 'light') {
      document.documentElement.classList.remove('dark');
      document.documentElement.classList.add('light');
    } else if (prefersColorScheme === 'dark') {
      document.documentElement.classList.remove('light');
      document.documentElement.classList.add('dark');
    }
  }, [prefersColorScheme]);

  return <></>;
}
