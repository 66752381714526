import React, { useContext } from 'react';
import { CartContext } from './cart-context';
import { useLocale } from '../../../hooks/use-locale';
import { Price } from '../../../components/price';
import { ChevronImage } from '../../../components/images/chevron';
import { CartInternalContext } from './cart-internal-context';

export function TotalHero () {
  const { price, priceLoading } = useContext(CartContext);
  const { open, setOpen, soleTrialSubscription } = useContext(CartInternalContext);
  const currencyCode = priceLoading ? '' : price.currency.code;
  const showTrialHero = soleTrialSubscription && Number(price.now.total) === 0;
  const { t } = useLocale();

  let content;

  if (priceLoading) {
    content = (
      <div className="w-20 h-5 lg:h-8 lg:w-32 rounded bg-gray-200 dark:bg-gray-600 animate-pulse">
        &nbsp;
      </div>
    );
  } else {
    if (showTrialHero) {
      const trialInterval = soleTrialSubscription.items.plan.trial.interval;
      const trialLength = soleTrialSubscription.items.plan.trial.length;
      content = (
        <span className="lg:text-3xl">
          {trialLength} {t(trialLength === 1 ? trialInterval.slice(0, -1) : trialInterval)} {t('cart.free')}
        </span>
      );
    } else {
      content = (
        <>
          <span className="lg:text-3xl">
            <Price currency={price.currency} amount={price.now.total} />
          </span>
          <span className="text-xs hidden lg:inline">&nbsp;{currencyCode}</span>
        </>
      );
    }
  }

  return (
    <div className="flex font-medium items-end lg:flex-wrap">
      <h2 className="flex-auto lg:text-xl">{t('cart.title')}</h2>

      <div className="flex-none lg:basis-full lg:mt-7">
        {content}
      </div>

      <div onClick={() => setOpen(!open)}>
        <ChevronImage
          className={`h-4 ml-3 -translate-y-1 lg:hidden ${open && 'rotate-180'}`}
        />
      </div>
    </div>
  );
}
