import React, { useContext, useEffect, useMemo, useState } from 'react';

import { CartInternalContext } from './cart-internal-context';
import { CartItemSubscription } from './cart-item-subscription';
import { CartItemAdjustment } from './cart-item-adjustment';
import { CartSubscription, FLAGS } from '../../../services/session';
import { useCheckoutSession } from '../../../hooks/use-checkout-session';
import { useLocale } from '../../../hooks/use-locale';

export function CartItems () {
  const {
    setShowCartEdits,
    showCartEdits
  } = useContext(CartInternalContext);
  const [{
    cart: {
      lineItems = [],
      subscriptions = []
    },
    flags
  }, updateCheckoutSession] = useCheckoutSession();
  const [selectedSubscription, setSelectedSubscription] = useState<CartSubscription>();
  const selectionsEnabled = flags.includes(FLAGS.DISPLAY_SUBSCRIPTIONS_AS_OPTIONS);
  const { t } = useLocale();

  const cartEditable = useMemo(() => {
    for (const lineItem of lineItems) {
      if (lineItem.quantityMutable) return true;
    }
    for (const sub of subscriptions) {
      if (sub.quantityMutable) return true;
      for (const addOn of sub.addOns) {
        if (addOn.quantityMutable) return true;
      }
    }
    return false;
  }, [lineItems, subscriptions]);

  const subscriptionsWithQuantities = useMemo(() => subscriptions.filter(s => s.quantity !== '0'), [subscriptions]);
  const selectionHasBeenMade = subscriptionsWithQuantities.length === 1;

  const handleSelectSubscription = (sub: CartSubscription) => {
    if (!selectionsEnabled) return;
    if (sub.ephemeralId === selectedSubscription?.ephemeralId) return;

    const newSubscriptions: CartSubscription[] = JSON.parse(JSON.stringify(subscriptions));

    newSubscriptions.forEach(s => {
      if (s.ephemeralId !== sub.ephemeralId) {
        s.quantity = '0';
      } else {
        s.quantity = s.quantityMin && Number(s.quantityMin) > 0 ? s.quantityMin : '1';
      }
    });

    updateCheckoutSession({ cart: { subscriptions: newSubscriptions } });
    setSelectedSubscription(sub);
  };

  const selectedStateFor = (sub: CartSubscription) => {
    if (!selectionsEnabled) return;
    if (!selectedSubscription) return;
    return sub.ephemeralId === selectedSubscription.ephemeralId;
  };

  useEffect(() => {
    if (!selectionsEnabled) return;
    if (!selectedSubscription) {
      if (selectionHasBeenMade) {
        handleSelectSubscription(subscriptionsWithQuantities[0]);
      } else {
        handleSelectSubscription(subscriptions[0]);
      }
    }

  }, []);

  return (
    <div className="border-b border-gray-300 py-5">
      {subscriptions.map((sub, index) => (
        <CartItemSubscription
          onSelect={() => handleSelectSubscription(sub)}
          selected={selectedStateFor(sub)}
          subscription={sub}
          key={index}
        />
      ))}
      {lineItems.map((adj, index) => (
        <CartItemAdjustment
          adjustment={adj}
          key={index}
        />
      ))}
      {cartEditable && (
        <div className="mt-4">
          <a
            href="#"
            className="font-medium"
            onClick={event => {
              event.preventDefault();
              setShowCartEdits(!showCartEdits);
            }}
          >
            {showCartEdits ? t('cart.edit.submit') : t('cart.edit.label')}
          </a>
        </div>
      )}
    </div>
  );
}
