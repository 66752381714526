import { useContext } from 'react';
import { PaymentMethodContext, PaymentMethodContextProps } from '@/contexts/payment-method-context';

export function usePaymentMethodContext (): PaymentMethodContextProps {
  const context = useContext(PaymentMethodContext);

  if (context === undefined) {
    throw new Error('usePaymentMethodContext must be used within a PaymentMethodProvider');
  }

  if (context === null) {
    throw new Error('PaymentMethodContext has not been Provided');
  }

  return context;
}
