import camelcaseKeys from 'camelcase-keys';
import { SnakeCasedPropertiesDeep } from 'type-fest';
import { RecurlyError, TokenPayload } from '@recurly/recurly-js';
import snakecaseKeys from 'snakecase-keys';

import { Currency } from '../components/price';
import { RecurlyWithInternals } from '../types/recurly';
import { Purchase } from './purchase';

type CartItem = {
  ephemeralId?: string;
  quantity: string;
  quantityMin?: string;
  quantityMax?: string;
  quantityMutable: boolean;
}

export type CartSubscriptionAddOn = CartItem & {
  code: string;
};

export type CartSubscription = CartItem & {
  addOns: CartSubscriptionAddOn[],
  planId: string;
};

export type CartLineItem = CartItem & {
  itemId: string;
};

export type Cart = {
  coupons: { code: string; }[];
  currencies: Currency[];
  currency: Currency;
  giftCards: { code: string; }[];
  lineItems: CartLineItem[];
  subscriptions: CartSubscription[];
};

export type PaymentMethodType = 'credit_card'
                              | 'paypal'
                              | 'apple_pay'
                              | 'amazon'
                              | 'ach'
                              | 'bacs'
                              | 'becs'
                              | 'sepa'
                              // | 'sepa_direct_debit'
                              // | 'pending_adyen_hpp'
                              | 'ideal'
                              | 'sofort'
                              // | 'direct_e_banking'
                              // | 'token'
                              | 'boleto'
                              | 'venmo'
                              // | 'braintree_v_zero'
                              // | 'braintree_apple_pay'
                              // | 'qiwi_wallet'
                              // | 'roku'
                              ;

type PaymentMethodCurrency = Currency & {
  cardTypes: CardType[];
};

type CardType = 'american_express' | 'discover' | 'diners_club' | 'master' | 'visa' | 'jcb' | 'union_pay';

export type AdyenCredentials = { clientKey: string; };
export type BraintreeCredentials = { tokenizationKey: string; };
export type StripeCredentials = { publishableKey: string; };

export type PaymentGateway = {
  code: string;
  credentials: AdyenCredentials
             | BraintreeCredentials
             | StripeCredentials
             | {};
  currencies: Currency[];

  // Gateway type values are included if the gateway supports
  // the following payment methods:
  //
  // Amazon, PayPal, and Apple Pay.
  //
  // These payment methods vary in their client implementation, thus we must
  // derive that implementaiton from gateway configuration
  type: 'amazon'
      | 'amazon_eu'
      | 'amazon_uk'
      | 'amazon_v2'
      | 'amazon_v2_eu'
      | 'amazon_v2_uk'
      | 'adyen'
      // | 'adyen_ach'
      // | 'authorize'
      // | 'beanstream'
      | 'braintree_blue'
      // | 'cardconnect'
      // | 'check_commerce'
      | 'cybersource'
      // | 'ebanx'
      // | 'firstdata'
      // | 'go_cardless'
      // | 'linkpoint'
      | 'litle_online'
      // | 'mes'
      // | 'ogone'
      | 'orbital'
      // | 'payeezy'
      // | 'payflowpro'
      // | 'payflowpro_uk'
      // | 'paypal'
      | 'paypal_business'
      // | 'paypal_ca'
      | 'paypal_complete'
      // | 'paypal_uk'
      // | 'qbms'
      // | 'roku'
      // | 'sagepay'
      | 'stripe'
      // | 'test'
      // | 'tsys'
      // | 'worldpay';
};

export type PaymentMethod = {
  currencies: PaymentMethodCurrency[];
  gateways: PaymentGateway[];
  type: PaymentMethodType;
};

export type CheckoutSessionToken = {
  accentColor: string;
  cancelUrl?: string;
  cart: Cart;
  confirmUrl?: string;
  finishUrl?: string;
  flags: string[];
  iconUrl?: string;
  id: string;
  locale?: string;
  logoUrl?: string;
  paymentMethods: PaymentMethod[];
  prefersColorScheme?: 'dark' | 'light';
  primaryColor: string;
  privacyPolicyUrl?: string;
  purchases: Purchase[];
  site: {
    defaultCurrency: string;
    addressRequirement: 'none' | 'zip' | 'streetzip' | 'full';
    name: string;
  };
  tosUrl?: string;
  type: string;
  updatedAt: string;
  expiredAt?: string;
};

export type CheckoutSessionStored = CheckoutSessionToken | null;
export type CheckoutSessionTokenPayload = TokenPayload & SnakeCasedPropertiesDeep<CheckoutSessionToken>;

export const FLAGS = {
  ACCEPT_BRAINTREE_PAYPAL: 'accept_braintree_paypal',
  ACCEPT_BRAINTREE_VENMO: 'accept_braintree_venmo',
  ACCEPT_COMPANY_NAME: 'accept_company_name',
  ACCEPT_COUPONS: 'accept_coupons',
  ACCEPT_PHONE_NUMBER: 'accept_phone_number',
  ACCEPT_SHIPPING_ADDRESS: 'accept_shipping_address',
  ACCEPT_TAX_IDENTIFIER: 'accept_tax_identifier',
  ACCEPT_VAT_NUMBER: 'accept_vat_number',
  DISABLE_CURRENCY_SELECTION: 'disable_currency_selection',
  DISPLAY_SUBSCRIPTIONS_AS_OPTIONS: 'display_subscriptions_as_options'
};

export const sessionIsActive = (checkoutSession?: CheckoutSessionStored) => (
  !!(sessionExists(checkoutSession) && checkoutSession?.purchases.length === 0)
);

export const sessionHasPurchase = (checkoutSession?: CheckoutSessionStored) => (
  !!(sessionExists(checkoutSession) && (checkoutSession?.purchases.length || 0) > 0)
);

export const sessionExists = (checkoutSession?: CheckoutSessionStored) => (
  !!(checkoutSession && !checkoutSession.expiredAt)
);

const withoutEphemeralIds = ({ ephemeralId: _ephemeralId, ...rest }: CartItem) => rest;

export class InvalidCheckoutSessionRequestError extends Error {
  cause: RecurlyError;

  constructor (cause: RecurlyError) {
    super('Not Found.');
    this.cause = cause;
  }
}

/**
 * Performs a token update API call with the most recent token, on a 3 second interval
 *
 * Queueing a token will purge any prior updates within the current interval bucket.
 *
 */
export class CheckoutSessionRemoteUpdater {
  static queued?: [CheckoutSessionToken, Function, RecurlyWithInternals];
  static intervalId = setInterval(this.process.bind(this), 3000);

  static queue (
    checkoutSession: CheckoutSessionToken,
    setCheckoutSession: Function,
    recurly: RecurlyWithInternals
  ) {
    this.queued = [checkoutSession, setCheckoutSession, recurly];
  }

  static process () {
    if (!this.queued) return;
    const [checkoutSession, setCheckoutSession, recurly] = this.queued;
    updateCheckoutSession(recurly, checkoutSession)
      .then(() => {
        CheckoutSessionRemoteValidator.set(
          checkoutSession,
          setCheckoutSession,
          recurly
        );
      });
    delete this.queued;
  }
}

export class CheckoutSessionRemoteValidator {
  static backoffInterval = 30000; // 30 s in ms
  static currentCheckoutSession?: CheckoutSessionToken;
  static onCheck?: Function;
  static timeout = 172800000; // 48 hrs in ms
  static timeoutId?: ReturnType<typeof setTimeout>;

  static set (
    checkoutSession: CheckoutSessionToken,
    setCheckoutSession: Function,
    recurly: RecurlyWithInternals,
    backoffCount?: number
  ) {
    if (
      checkoutSession.id === this.currentCheckoutSession?.id
      && checkoutSession.updatedAt === this.currentCheckoutSession?.updatedAt
    ) {
      return;
    }

    clearTimeout(this.timeoutId);

    if (this.onCheck) {
      window.document.removeEventListener('visibilitychange', this.onCheck);
    }

    this.currentCheckoutSession = checkoutSession;

    const onCheck = this.onCheck = () => {
      // return if we're younger than expected expiration
      if (this.timeoutForCheckoutSession(checkoutSession) > 0) return;
      this.check(checkoutSession, setCheckoutSession, recurly, backoffCount);
    };
    this.timeoutId = setTimeout(onCheck, this.timeoutForCheckoutSession(checkoutSession, backoffCount));
    window.document.addEventListener('visibilitychange', onCheck);
  }

  static check (
    checkoutSession: CheckoutSessionToken,
    setCheckoutSession: Function,
    recurly: RecurlyWithInternals,
    backoffCount = 0
  ) {
    if (!sessionIsActive(checkoutSession)) return;
    return getCheckoutSession(recurly, checkoutSession.id)
      .then(newCheckoutSession => this.set(newCheckoutSession, setCheckoutSession, recurly, backoffCount + 1))
      .catch(() => {
        if (this.currentCheckoutSession && this.currentCheckoutSession !== checkoutSession) return;
        setCheckoutSession({
          id: checkoutSession.id,
          expiredAt: (new Date).toISOString()
        });
      });
  }

  static timeoutForCheckoutSession (
    { updatedAt }: CheckoutSessionToken,
    backoffCount = 0
  ) {
    const timeout = (Date.parse(updatedAt) + this.timeout - Date.now());

    // In cases where our expected TTL expiration has passed, but the session is still
    // available, we provide a progressive backoff
    if (timeout < 0) {
      return this.backoffInterval * backoffCount;
    }

    return timeout;
  }
}

const translateCheckoutSessionPayload = (
  res: CheckoutSessionTokenPayload
): CheckoutSessionToken => camelcaseKeys(res, { deep: true });

const handleCheckoutSessionResponseError = (err: RecurlyError) => {
  if (err.code === 'invalid-public-key') {
    // We must have an invalid hostname which cannot validate
    throw new InvalidCheckoutSessionRequestError(err);
  }

  throw err;
};

/**
 * Gets a CheckoutSession using the public API
 *
 * @param  {RecurlyWithInternals} recurly
 * @param  {String} checkoutSessionId
 * @return {Promise<CheckoutSessionToken>}
 */
export function getCheckoutSession (
  recurly: RecurlyWithInternals,
  checkoutSessionId = ''
): Promise<CheckoutSessionToken> {
  return recurly.request
    .get({ route: `/tokens/${checkoutSessionId}` })
    .then(translateCheckoutSessionPayload)
    .catch(handleCheckoutSessionResponseError);
}

/**
 * Creates a CheckoutSession using the public API
 *
 * @param  {RecurlyWithInternals} recurly
 * @param  {Object} body
 * @return {Promise<CheckoutSessionToken | {}>}
 */
export function createCheckoutSession (
  recurly: RecurlyWithInternals,
  body = {}
): Promise<CheckoutSessionStored> {
  return recurly.request
    .post({ route: '/tokens', data: { type: 'checkout_session', referrer: document.referrer, ...body } })
    .then(translateCheckoutSessionPayload)
    .catch(handleCheckoutSessionResponseError);
}

/**
 * Updates a CheckoutSession using the public API
 *
 * @param  {RecurlyWithInternals} recurly
 * @param  {CheckoutSessionToken} checkoutSession
 * @return {Promise<CheckoutSessionStored>}
 */
function updateCheckoutSession (
  recurly: RecurlyWithInternals,
  checkoutSession: CheckoutSessionToken
): Promise<CheckoutSessionStored> {
  if (Object.keys(checkoutSession).length === 0) return Promise.resolve(null);

  const updateBody = (sess: CheckoutSessionToken) => JSON.stringify({
    cart: snakecaseKeys({
      coupons: sess?.cart?.coupons,
      currency: sess?.cart?.currency,
      giftCards: sess?.cart?.giftCards,
      subscriptions: sess?.cart?.subscriptions.map(withoutEphemeralIds),
      lineItems: sess?.cart?.lineItems.map(withoutEphemeralIds)
    })
  });

  return fetch(recurly.url(`/tokens/${checkoutSession.id}`), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Recurly-Credential-Checkout-Hostname': recurly.config.hostname
    },
    body: updateBody(checkoutSession)
  })
    .then(response => response.json())
    .then(translateCheckoutSessionPayload)
    .catch(handleCheckoutSessionResponseError);
}
