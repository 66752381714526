import React, { useEffect } from 'react';
import { useRecurly } from '@recurly/react-recurly';

import { PaymentMethodOption } from './option';
import { Field, SelectInput, TextInput } from '../../form';
import { useLocale } from '@/hooks/use-locale';
import { usePaymentMethodContext } from '@/hooks/use-payment-method-context';

export function AchPaymentMethodOption () {
  const { t } = useLocale();
  const recurly = useRecurly();
  const {
    paymentMethod,
    paymentMethodForType,
    setBillingInfoTokenCreator,
  } = usePaymentMethodContext();
  const achPaymentMethod = paymentMethodForType('ach');

  useEffect(() => {
    if (paymentMethod?.type !== achPaymentMethod?.type) return;
    setBillingInfoTokenCreator(() => (form, done) => recurly.bankAccount.token(form, done));
  }, [paymentMethod]);

  return (
    <PaymentMethodOption title={t('payment-method.ach.name')} type="ach">
      <Field
        label={t('payment-method.ach.routing-number')}
        className="col-span-2"
        name="routing_number"
        required
      >
        <TextInput />
      </Field>
      <Field
        label={t('payment-method.ach.account-number')}
        className="col-span-2"
        name="account_number"
        required
      >
        <TextInput />
      </Field>
      <Field
        label={t('payment-method.ach.account-number-confirmation')}
        className="col-span-2"
        name="account_number_confirmation"
        required
      >
        <TextInput />
      </Field>
      <Field
        label={t('payment-method.ach.account-type.label')}
        className="col-span-2"
        name="account_type"
        required
      >
        <SelectInput
          options={[
            { name: t('payment-method.ach.account-type.checking'), value: 'checking' },
            { name: t('payment-method.ach.account-type.savings'), value: 'savings' }
          ]}
        />
      </Field>
      <Field
        label={t('payment-method.ach.name-on-account')}
        className="col-span-2"
        name="name_on_account"
        required
      >
        <TextInput />
      </Field>
    </PaymentMethodOption>
  );
}
