import React, { PropsWithChildren, useId } from 'react';
import { twMerge } from 'tailwind-merge';

import { Period } from '../../../services/purchase';
import { Price, PriceObject } from '../../../components/price';
import { useLocale } from '../../../hooks/use-locale';

export function CartItem ({
  associated = false,
  children,
  onSelect,
  period,
  prependText = '',
  price,
  priceClassName,
  quantity,
  selected,
  title = '',
  testGroupId,
}: {
  associated?: boolean;
  onSelect?: () => void;
  period: Period;
  prependText?: string;
  price: PriceObject;
  priceClassName: string;
  quantity: number;
  selected?: boolean;
  title: string | React.JSX.Element;
  testGroupId?: string;
} & PropsWithChildren) {
  const { t } = useLocale();
  const radioId = useId();
  const selectable = selected !== undefined;

  return (
    <div
      data-testid={testGroupId ? `cart-item-${testGroupId}` : null}
      className={twMerge(
        `
          grid
          py-3
          dark:border-slate-600
          last:border-b-0
          last:pb-0
          cursor-default
        `,
        associated && 'border-l-2 pl-4',
        quantity < 1 && 'opacity-40',
        selectable && 'grid-cols-[min-content_1fr] border-blue-500 border-2 rounded-lg p-4 mb-4'
      )}
      onClick={() => onSelect?.()}
    >
      {selectable && onSelect && (
        <div className="row-span-2 pt-1 pr-4">
          <input
            type="radio"
            name="cart-item-selection"
            id={radioId}
            onChange={() => onSelect()}
            checked={selected}
          />
        </div>
      )}
      <div className="flex gap-x-2">
        <div data-testid="cart-plan-title"
          className="flex-auto">
          {title}
        </div>
        <p className={twMerge('flex-none', priceClassName)}>
          {quantity > 0 && (
            <>
              {prependText && <>{prependText}&nbsp;</>}
              <Price {...price} />
              {period && `/${t.intervalFor(period)}`}
            </>
          )}
        </p>
      </div>
      {children && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
}
