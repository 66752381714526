import { CartLineItem } from '@/services/session';
import { RecurlyWithInternals } from '@/types/recurly';
import { useRecurly } from '@recurly/react-recurly';
import React, { useContext, useEffect, useState } from 'react';
import { useCheckoutSession } from '@/hooks/use-checkout-session';
import { CartItem } from './cart-item';
import { NumericInput } from '@/components/form';
import { Price } from '@/components/price';
import { CartInternalContext } from '@/contexts/cart-internal-context';
import { useCartContext } from '@/hooks/use-cart-context';

export function CartItemAdjustment ({
  adjustment: {
    ephemeralId,
    quantity,
    quantityMin,
    quantityMax,
    quantityMutable
  }
}: {
  adjustment: CartLineItem;
}) {
  const recurly = useRecurly() as RecurlyWithInternals;
  const { pricing } = useCartContext();
  const { currency } = useContext(CartInternalContext);
  const [itemName, setItemName] = useState('Item');
  const [{ cart }, updateCheckoutSession] = useCheckoutSession();
  const { showCartEdits } = useContext(CartInternalContext);

  const price = pricing.price.now.items.find(i => i.id === ephemeralId);
  const priceItem = pricing.items.adjustments.find(({ id }) => id === ephemeralId);
  const itemCode = priceItem?.itemCode;

  useEffect(() => {
    if (!itemCode) return;
    recurly.item({ itemCode }).then(({ name }) => setItemName(name));
  }, [itemCode]);

  if (!priceItem) return;

  return (
    <CartItem
      price={{ currency, amount: price.amount }}
      quantity={quantity}
      title={itemName}
      testGroupId='adjustment'
    >
      <div className="text-sm text-checkout-subtle">
        {showCartEdits && quantityMutable && (
          <div>
            <NumericInput
              setValue={value => {
                const lineItems = JSON.parse(JSON.stringify(cart.lineItems));

                lineItems
                  .find((l: CartLineItem) => l.ephemeralId === ephemeralId)
                  .quantity = value;

                updateCheckoutSession({ cart: { lineItems } });
              }}
              min={quantityMin}
              max={quantityMax}
              value={quantity}
            />
            &nbsp;x <Price currency={currency} amount={price.unitAmount} />
          </div>
        ) || (
          (Number(quantity) > 1 || quantityMutable) && (
            <p>
              {quantity} x <Price currency={currency} amount={price.unitAmount} />
            </p>
          )
        )}
      </div>
    </CartItem>
  );
}
