import React from 'react';
import { PaymentMethod } from '@/services/session';

import {
  AchAcceptanceMark,
  AmazonPayAcceptanceMark,
  ApplePayAcceptanceMark,
  BankAccountAcceptanceMark,
  CreditCardAcceptanceMark,
  PayPalAcceptanceMark,
  SepaAcceptanceMark,
  VenmoAcceptanceMark
} from '../../images/acceptance-marks';

type AcceptanceMarkProps = {
  type: PaymentMethod;
}

const acceptanceMarkForPaymentMethod = ({ type }: PaymentMethod) => ({
  ach: <AchAcceptanceMark />,
  amazon: <AmazonPayAcceptanceMark />,
  apple_pay: <ApplePayAcceptanceMark />,
  bacs: <BankAccountAcceptanceMark />,
  becs: <BankAccountAcceptanceMark />,
  boleto: <BankAccountAcceptanceMark />,
  credit_card: <CreditCardAcceptanceMark />,
  ideal: <BankAccountAcceptanceMark />,
  paypal: <PayPalAcceptanceMark />,
  sepa: <SepaAcceptanceMark />,
  sofort: <BankAccountAcceptanceMark />,
  venmo: <VenmoAcceptanceMark />
}[type]);

export function AcceptanceMark ({ type }: AcceptanceMarkProps): React.JSX.Element {
  return acceptanceMarkForPaymentMethod(type);
}
