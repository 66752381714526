import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from 'react';
import { RecurlyError } from '@recurly/recurly-js';
import { useActionData, useParams } from 'react-router-dom';
import { useRecurly } from '@recurly/react-recurly';

import { preferredLocaleTag } from '../services/locale';
import {
  CheckoutSessionToken,
  CheckoutSessionStored,
  CheckoutSessionRemoteUpdater,
  CheckoutSessionRemoteValidator,
  createCheckoutSession,
  getCheckoutSession,
  InvalidCheckoutSessionRequestError
} from '../services/session';
import { EmptyState, Loading, NotFound, SessionExpired } from './empty-state';
import { useSessionStorage } from '../hooks/use-storage';
import { RecurlyWithInternals } from '../types/recurly';

type CheckoutSessionValidityTest = (_checkoutSession: CheckoutSessionStored) => boolean;
type CheckoutSessionProviderParams = {
  test: CheckoutSessionValidityTest;
  onTestFailure?(): React.JSX.Element;
} & PropsWithChildren;
export type CheckoutSessionContextParams = [
  CheckoutSessionToken,
  Dispatch<Partial<CheckoutSessionStored>>,
  Function
];

const sessionChanged = (sess: CheckoutSessionStored, newSess: CheckoutSessionStored) => {
  try {
    return JSON.stringify(sess) !== JSON.stringify(newSess);
  } catch {
    return false;
  }
};

export const CheckoutSessionContext = React.createContext<CheckoutSessionContextParams>([null, () => {}, () => {}]);

const useStoredCheckoutSession = (
  test: CheckoutSessionValidityTest
): CheckoutSessionContextParams => {
  const [
    checkoutSession,
    setCheckoutSession
  ] = useSessionStorage<CheckoutSessionStored>('__recurly__.checkoutSession', null);

  const recurly = useRecurly() as RecurlyWithInternals;

  useMemo(() => {
    if (test(checkoutSession)) return;
    setCheckoutSession(null);
  }, [checkoutSession?.id]);

  if (checkoutSession) {
    CheckoutSessionRemoteValidator.set(
      checkoutSession,
      setCheckoutSession,
      recurly
    );
  }

  const updateCheckoutSession = (newCheckoutSession: CheckoutSessionStored) => {
    if (checkoutSession && newCheckoutSession && sessionChanged(checkoutSession, newCheckoutSession)) {
      CheckoutSessionRemoteUpdater.queue(
        newCheckoutSession,
        setCheckoutSession,
        recurly
      );
    }
    setCheckoutSession(newCheckoutSession);
  };

  return [
    checkoutSession as CheckoutSessionToken,
    updateCheckoutSession,
    () => getCheckoutSession(recurly, checkoutSession?.id).then(updateCheckoutSession)
  ];
};

const handleCheckoutSessionError = (
  setFallback: Dispatch<SetStateAction<React.JSX.Element>>
) => (
  err: InvalidCheckoutSessionRequestError | RecurlyError
) => {
  if (err instanceof InvalidCheckoutSessionRequestError || err.code === 'not-found') {
    setFallback(<NotFound />);
  } else {
    console.error(err);
    setFallback(<EmptyState />);
  }
};

const useFallback = (
  checkoutSession: CheckoutSessionStored,
  test: CheckoutSessionValidityTest
): [React.JSX.Element, Dispatch<SetStateAction<React.JSX.Element>>] => {
  const [fallback, setFallback] = useState<React.JSX.Element>(<></>);
  useEffect(() => {
    if (test(checkoutSession)) return;
    setFallback(checkoutSession ? <SessionExpired /> : <Loading />);
  }, [checkoutSession]);
  return [fallback, setFallback];
};

/**
 * Handles CheckoutSession management for /s/:checkoutSessionId
 *
 * @param {Object} params
 * @param {CheckoutSessionValidityTest} params.test A function to determine the validity of a CheckoutSessionToken for
 *                                                  a given context
 * @param {ReactNode} params.children
 */
export function SessionFromSessionIdParam ({ test, children }: CheckoutSessionProviderParams) {
  const [checkoutSession, setCheckoutSession] = useStoredCheckoutSession(test);
  const { checkoutSessionId: checkoutSessionIdParam } = useParams();
  const [fallback, setFallback] = useFallback(checkoutSession, test);
  const recurly = useRecurly() as RecurlyWithInternals;

  useEffect(() => {
    if (checkoutSession?.id !== checkoutSessionIdParam) {
      setCheckoutSession(null);
    }

    getCheckoutSession(recurly, checkoutSessionIdParam)
      .then(value => setCheckoutSession(value))
      .catch(handleCheckoutSessionError(setFallback));
  }, [checkoutSessionIdParam]);

  return test(checkoutSession)
    ? (
      <CheckoutSessionContext.Provider value={[checkoutSession, setCheckoutSession]}>
        {children}
      </CheckoutSessionContext.Provider>
    )
    : fallback;
}

/**
 * Handles CheckoutSession management for /c/:checkoutConfigurationId
 *
 * @param {Object} params
 * @param {CheckoutSessionValidityTest} params.test A function to determine the validity of a CheckoutSessionToken for
 *                                                  a given context
 * @param {ReactNode} params.children
 */
export function SessionFromConfigurationIdParam ({ test, children }: CheckoutSessionProviderParams) {
  const [checkoutSession, setCheckoutSession] = useStoredCheckoutSession(test);
  const [
    checkoutConfigurationId,
    setCheckoutConfigurationId
  ] = useSessionStorage('__recurly__.checkoutConfigurationId', '');
  const { checkoutConfigurationId: checkoutConfigurationIdParam } = useParams();
  const [fallback, setFallback] = useFallback(checkoutSession, test);
  const recurly = useRecurly() as RecurlyWithInternals;

  useEffect(() => {
    let ignore = false;
    // if a checkoutSession exists, and its checkoutConfigurationId matches the param,
    // we may re-use the checkoutSession
    if (checkoutSession && checkoutConfigurationId === checkoutConfigurationIdParam) {
      return;
    }

    createCheckoutSession(
      recurly,
      {
        checkout_configuration_id: checkoutConfigurationIdParam,
        locale: preferredLocaleTag()
      }
    )
      .then(value => {
        if (ignore) return;
        setCheckoutSession(value);
        setCheckoutConfigurationId(checkoutConfigurationIdParam);
      })
      .catch(handleCheckoutSessionError(setFallback));

    return () => { ignore = true; };
  }, [checkoutSession, checkoutConfigurationId, checkoutConfigurationIdParam]);

  return test(checkoutSession)
    ? (
      <CheckoutSessionContext.Provider value={[checkoutSession, setCheckoutSession]}>
        {children}
      </CheckoutSessionContext.Provider>
    )
    : fallback;
}

/**
 * Handles CheckoutSession management for /
 *
 * @param {Object} params
 * @param {CheckoutSessionValidityTest} params.test A function to determine the validity of a CheckoutSessionToken for
 *                                                  a given context
 * @param {ReactNode} params.children
 */
export function SessionFromHostname ({ test, children }: CheckoutSessionProviderParams) {
  const [checkoutSession, setCheckoutSession] = useStoredCheckoutSession(test);
  const [fallback, setFallback] = useFallback(checkoutSession, test);
  const recurly = useRecurly() as RecurlyWithInternals;

  useEffect(() => {
    let ignore = false;
    if (checkoutSession) return;

    createCheckoutSession(
      recurly,
      {
        locale: preferredLocaleTag()
      }
    )
      .then(value => {
        if (ignore) return;
        setCheckoutSession(value);
      })
      .catch(handleCheckoutSessionError(setFallback));

    return () => { ignore = true; };
  }, [checkoutSession]);

  return test(checkoutSession)
    ? (
      <CheckoutSessionContext.Provider value={[checkoutSession, setCheckoutSession]}>
        {children}
      </CheckoutSessionContext.Provider>
    )
    : fallback;
}

/**
 * Handles CheckoutSession management for /order/:checkoutSessionId
 *
 * @param {Object} params
 * @param {CheckoutSessionValidityTest} params.test A function to determine the validity of a CheckoutSessionToken for
 *                                                  a given context
 * @param {Function} [params.onTestFailure] A function called if the validity test fails
 * @param {ReactNode} params.children
 */
export function SessionFromActionOrSessionIdParam ({ test, onTestFailure, children }: CheckoutSessionProviderParams) {
  const actionData = useActionData() as { checkoutSession: CheckoutSessionToken };
  const { checkoutSessionId: checkoutSessionIdParam } = useParams();
  const [checkoutSession, setCheckoutSession] = useState<CheckoutSessionStored>(actionData?.checkoutSession);
  const [storedCheckoutSession, setStoredCheckoutSession] = useStoredCheckoutSession(() => true);
  const [fallback, setFallback] = useFallback(checkoutSession, test);
  const recurly = useRecurly() as RecurlyWithInternals;

  useEffect(() => {
    // When a checkoutSession originates from the request, clear it from storage
    if (checkoutSession && storedCheckoutSession?.id === checkoutSession.id) {
      setStoredCheckoutSession(null);
    }

    if (!checkoutSession) {
      getCheckoutSession(recurly, checkoutSessionIdParam)
        .then(value => setCheckoutSession(value))
        .catch(handleCheckoutSessionError(setFallback));
    } else if (checkoutSession.id !== checkoutSessionIdParam) {
      // get out of here?
    } else if (test(checkoutSession)) {
      return;
    } else if (onTestFailure) {
      setFallback(onTestFailure());
    }
  }, [checkoutSessionIdParam, checkoutSession]);

  return test(checkoutSession)
    ? (
      <CheckoutSessionContext.Provider value={[checkoutSession, setCheckoutSession]}>
        {children}
      </CheckoutSessionContext.Provider>
    )
    : fallback;
}
