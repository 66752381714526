import React, { PropsWithChildren, useContext, useId } from 'react';
import { twMerge } from 'tailwind-merge';
import { PaymentMethodSelectorContext } from '../payment-methods';
import { PaymentMethodType } from '@/services/session';
import { usePaymentMethodContext } from '@/hooks/use-payment-method-context';

export function PaymentMethodOption ({
  children,
  title,
  type
}: PropsWithChildren & {
  title: string;
  type: PaymentMethodType;
}) {
  const id = useId();
  const { optionCount } = useContext(PaymentMethodSelectorContext);
  const {
    paymentMethod,
    paymentMethodForType,
    setPaymentMethod
  } = usePaymentMethodContext();

  const select = () => !selected && setPaymentMethod(paymentMethodForType(type));
  const selected = type === paymentMethod?.type;

  if (optionCount < 2) {
    return children;
  }

  return (
    <label
      className={twMerge(
        'rounded-lg ring-1 ring-gray-400 p-4 col-span-2',
        selected && 'ring-2 ring-checkout-brand'
      )}
      onClick={select}
      htmlFor={id}
    >
      <input
        type="radio"
        id={id}
        name="payment-method-selector--type"
        onChange={select}
        checked={selected}
        aria-describedby={`${id}-description`}
      />
      <label
        className={twMerge('font-bold ml-3', !selected && 'text-checkout-subtle')}
        htmlFor={id}
        id={`${id}-description`}
      >
        {title}
      </label>
      {selected && (
        <div className="mt-6 grid gap-3">
          {children}
        </div>
      )}
    </label>
  );
}
