import React, { useEffect, useMemo, useState } from 'react';
import { BraintreeConfig, PayPalConfig } from '@recurly/recurly-js';
import { useRecurly } from '@recurly/react-recurly';

import { Button } from '../../form';
import {
  gatewayForPaymentMethodAndGatewayType,
} from '../payment-methods';
import { BraintreeCredentials, PaymentGateway, PaymentMethod } from '@/services/session';
import { PayPalLogoImage } from '../../images/paypal-logo';
import { useCheckoutSession } from '@/hooks/use-checkout-session';
import { usePaymentMethodContext } from '@/hooks/use-payment-method-context';
import { ExpressCheckoutProps } from '../express-checkout';

export function PayPalButton ({ onError, onToken }: ExpressCheckoutProps) {
  const [canSubmit, setCanSubmit] = useState(true);
  const recurly = useRecurly();
  const [{
    site: {
      name: siteName
    }
  }] = useCheckoutSession();
  const {
    paymentMethodForType,
    setPaymentMethod
  } = usePaymentMethodContext();

  const paypalPaymentMethod = paymentMethodForType('paypal') as PaymentMethod;
  const paypalIntegrationMode = (type: PaymentGateway['type']) => gatewayForPaymentMethodAndGatewayType(
    paypalPaymentMethod,
    type
  );

  let paypalOptions: PayPalConfig = {};
  let maybeBraintreeGateway = paypalIntegrationMode('braintree_blue');

  if (paypalIntegrationMode('paypal_complete')) {
    paypalOptions.payPalComplete = true;
  } else if (maybeBraintreeGateway) {
    (paypalOptions as BraintreeConfig).braintree = {
      clientAuthorization: (maybeBraintreeGateway.credentials as BraintreeCredentials).tokenizationKey
    };
  } else if (paypalIntegrationMode('paypal_business')) {
    paypalOptions.display = { displayName: siteName };
  } else {
    return;
  }

  const paypal = useMemo(() => recurly.PayPal(paypalOptions), [paypalOptions]);

  useEffect(() => {
    paypal.on('error', (error) => {
      setCanSubmit(true);
      onError(error);
    });
    paypal.on('cancel', () => {
      setCanSubmit(true);
    });
    paypal.on('token', (token) => {
      setCanSubmit(true);
      setPaymentMethod(paypalPaymentMethod);
      onToken(token);
    });
  }, [paypal]);

  return (
    <Button
      className="
      bg-[#ffc439]
        min-w-full min-h-12 py-2 px-4 mb-2
        rounded font-bold border-transparent
        hover:brightness-95 col-span-2
      "
      onClick={(event) => {
        event.preventDefault();
        setCanSubmit(false);
        paypal.start();
      }}
      processing={!canSubmit}
    >
      <PayPalLogoImage className="mx-auto" />
    </Button>
  );
}
