import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useEffect } from 'react';
import { CheckoutPricingInstance, RecurlyError, TokenPayload } from '@recurly/recurly-js';

import { AchPaymentMethodOption } from './payment-methods/ach';
import { AmazonPayButton } from './payment-methods/amazon';
import {
  AchAcceptanceMark,
  AmazonPayAcceptanceMark,
  ApplePayAcceptanceMark,
  BankAccountAcceptanceMark,
  CreditCardAcceptanceMark,
  PayPalAcceptanceMark,
  SepaAcceptanceMark,
  VenmoAcceptanceMark
} from '../images/acceptance-marks';
import { Address } from './address';
import { ApplePayButton } from './payment-methods/apple-pay';
import { BacsPaymentMethodOption } from './payment-methods/bacs';
import { BecsPaymentMethodOption } from './payment-methods/becs';
import { BoletoPaymentMethodOption } from './payment-methods/boleto';
import { CartContext } from './cart/cart-context';
import { CreditCardPaymentMethodOption } from './payment-methods/credit-card';
import { FieldGroup } from '../form';
import { FormError } from '../../services/purchase';
import { IdealPaymentMethodOption } from './payment-methods/ideal';
import { PaymentGateway, PaymentMethod, PaymentMethodType } from '../../services/session';
import { PayPalButton } from './payment-methods/paypal';
import { SepaPaymentMethodOption } from './payment-methods/sepa';
import { SofortPaymentMethodOption } from './payment-methods/sofort';
import { VenmoButton } from './payment-methods/venmo';
import { useLocale } from '../../hooks/use-locale';

type RecurlyErrorHandler = (_error: RecurlyError) => void;

export type ExpressCheckoutProps = {
  onError: RecurlyErrorHandler;
  onToken: (_token: TokenPayload) => void;
}

export const PaymentMethodContext = createContext<{
  acceptanceMarkForPaymentMethod(_paymentMethod: PaymentMethod): React.JSX.Element;
  acceptedPaymentMethods: PaymentMethod[],
  acceptPaymentMethodType(_type: PaymentMethodType | PaymentMethodType[]): boolean;
  billingAddress?: Address;
  paymentMethod?: PaymentMethod;
  paymentMethodForType(_type?: PaymentMethodType): PaymentMethod | undefined;
  pricing?: CheckoutPricingInstance;
  setBillingAddress: Dispatch<SetStateAction<Address>>;
  setBillingInfoTokenCreator: Dispatch<SetStateAction<Function | undefined>>;
  setPaymentMethod: Dispatch<SetStateAction<PaymentMethod | undefined>>;
  setShippingAddress: Dispatch<SetStateAction<Address | undefined>>;
  shippingAddress?: Address;
  usedExpressCheckout: boolean;
    }>({
      acceptanceMarkForPaymentMethod: () => <></>,
      acceptedPaymentMethods: [],
      acceptPaymentMethodType: () => false,
      paymentMethodForType: () => undefined,
      setBillingAddress: () => {},
      setBillingInfoTokenCreator: () => {},
      setPaymentMethod: () => {},
      setShippingAddress: () => {},
      usedExpressCheckout: false
    });

export const gatewayForPaymentMethodAndGatewayType = (
  paymentMethod: PaymentMethod,
  search: PaymentGateway['type']
) => paymentMethod.gateways.find(({ type }) => type === search);

export const acceptanceMarkForPaymentMethod = ({ type }: PaymentMethod) => ({
  ach: <AchAcceptanceMark />,
  amazon: <AmazonPayAcceptanceMark />,
  apple_pay: <ApplePayAcceptanceMark />,
  bacs: <BankAccountAcceptanceMark />,
  becs: <BankAccountAcceptanceMark />,
  boleto: <BankAccountAcceptanceMark />,
  credit_card: <CreditCardAcceptanceMark />,
  ideal: <BankAccountAcceptanceMark />,
  paypal: <PayPalAcceptanceMark />,
  sepa: <SepaAcceptanceMark />,
  sofort: <BankAccountAcceptanceMark />,
  venmo: <VenmoAcceptanceMark />
}[type]);

const environmentSupportsApplePay = () => window?.ApplePaySession?.canMakePayments();

export function PaymentInterface ({
  children,
  setBillingInfoToken,
  setError
} : PropsWithChildren & {
  setBillingInfoToken: Dispatch<SetStateAction<TokenPayload | undefined>>;
  setError: Dispatch<SetStateAction<FormError | undefined>>;
}) {
  const {
    usedExpressCheckout
  } = useContext(PaymentMethodContext);

  const { requiresBillingInfo } = useContext(CartContext);

  if (!requiresBillingInfo) {
    return children;
  }

  return (
    <>
      {usedExpressCheckout
        ? (
          <PaymentMethodSummary
            onClearPaymentMethod={() => {
              setBillingInfoToken(undefined);
            }}
          />
        )
        : (
          <ExpressCheckout
            onError={(error) => setError(new FormError(error))}
            onToken={(token) => setBillingInfoToken(token)}
          />
        )
      }
      {children}
      {!usedExpressCheckout && (
        <PaymentMethodSelector setError={error => setError(new FormError(error))} />
      )}
    </>
  );
}

function PaymentMethodSummary ({
  onClearPaymentMethod
}: {
  onClearPaymentMethod: Function;
}) {
  const { t } = useLocale();
  const {
    acceptanceMarkForPaymentMethod,
    paymentMethod,
    setPaymentMethod,
    usedExpressCheckout
  } = useContext(PaymentMethodContext);

  if (!usedExpressCheckout) return;
  if (!paymentMethod) return;

  return (
    <FieldGroup title={t('payment-info')}>
      <div className="col-span-2">
        <div className="bg-checkout-accent p-4 rounded-lg flex space-x-3">
          {acceptanceMarkForPaymentMethod(paymentMethod)}
          <span className="text-gray-500">
            {t('payment-method-selected', {
              paymentMethod: t(`payment-method.${paymentMethod.type.replace(/_/g, '-')}.name`)
            })}
          </span>
        </div>
        <p className="mt-4">
          <a
            href="#"
            className="font-medium"
            onClick={event => {
              event.preventDefault();
              setPaymentMethod(undefined);
              onClearPaymentMethod();
            }}
          >
            {t('change-payment-method')}
          </a>
        </p>
      </div>
    </FieldGroup>
  );
}

export const EXPRESS_CHECKOUT_PAYMENT_METHOD_TYPES = [
  'paypal',
  'venmo',
  'apple_pay',
  'amazon'
];

function ExpressCheckout (props: ExpressCheckoutProps) {
  const { t } = useLocale();
  const { acceptPaymentMethodType } = useContext(PaymentMethodContext);

  const acceptPayPal = acceptPaymentMethodType('paypal');
  const acceptVenmo = acceptPaymentMethodType('venmo');
  const acceptApplePay = acceptPaymentMethodType('apple_pay') && environmentSupportsApplePay();
  const acceptAmazonPay = acceptPaymentMethodType('amazon');

  if (!(acceptPayPal || acceptVenmo || acceptApplePay || acceptAmazonPay)) {
    return <></>;
  }

  return (
    <FieldGroup title={t('express-checkout')}>
      <div className="col-span-2 space-y-2">
        {acceptPayPal && (<PayPalButton {...props} />)}
        {acceptVenmo && (<VenmoButton {...props} />)}
        {acceptApplePay && (<ApplePayButton {...props} />)}
        {acceptAmazonPay && (<AmazonPayButton {...props} />)}
      </div>
    </FieldGroup>
  );
}

export const PaymentMethodSelectorContext = createContext<{
  optionCount: number;
  setError: RecurlyErrorHandler;
}>({
  optionCount: 0,
  setError: () => {}
});

const ORDERED_PAYMENT_METHOD_SELECTOR_TYPES: PaymentMethodType[] = [
  'credit_card',
  'ach',
  'bacs',
  'becs',
  'sepa',
  'boleto',
  'ideal',
  'sofort'
];

export function PaymentMethodSelector ({ setError }: { setError: RecurlyErrorHandler }) {
  const { t } = useLocale();
  const {
    acceptPaymentMethodType,
    acceptedPaymentMethods,
    paymentMethod,
    paymentMethodForType,
    setPaymentMethod
  } = useContext(PaymentMethodContext);

  const optionCount = acceptedPaymentMethods.filter(({ type }) => (
    ORDERED_PAYMENT_METHOD_SELECTOR_TYPES.includes(type))
  ).length;

  // Set the default payment method to the first available of our global ordering
  useEffect(() => {
    if (paymentMethod) return;

    const firstPaymentMethod = ORDERED_PAYMENT_METHOD_SELECTOR_TYPES.find(acceptPaymentMethodType);
    if (firstPaymentMethod) {
      setPaymentMethod(paymentMethodForType(firstPaymentMethod));
    }
  }, [acceptedPaymentMethods]);

  if (optionCount === 0) return;

  return (
    <PaymentMethodSelectorContext.Provider value={{ optionCount, setError }}>
      <FieldGroup title={t('payment-info')}>
        {acceptPaymentMethodType('credit_card') && <CreditCardPaymentMethodOption />}
        {acceptPaymentMethodType('ach') && <AchPaymentMethodOption />}
        {acceptPaymentMethodType('bacs') && <BacsPaymentMethodOption />}
        {acceptPaymentMethodType('becs') && <BecsPaymentMethodOption />}
        {acceptPaymentMethodType('sepa') && <SepaPaymentMethodOption />}
        {acceptPaymentMethodType('boleto') && <BoletoPaymentMethodOption />}
        {acceptPaymentMethodType('ideal') && <IdealPaymentMethodOption />}
        {acceptPaymentMethodType('sofort') && <SofortPaymentMethodOption />}
      </FieldGroup>
    </PaymentMethodSelectorContext.Provider>
  );
}
