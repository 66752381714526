import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useRecurly } from '@recurly/react-recurly';

import { Button } from '../../form';
import {
  ExpressCheckoutProps,
  PaymentMethodContext,
  gatewayForPaymentMethodAndGatewayType
} from '../payment-methods';
import { BraintreeCredentials, PaymentMethod } from '../../../services/session';
import { VenmoLogoImage } from '../../images/venmo-logo';

export function VenmoButton ({ onError, onToken }: ExpressCheckoutProps) {
  const [canSubmit, setCanSubmit] = useState(true);
  const recurly = useRecurly();
  const {
    paymentMethodForType,
    setPaymentMethod
  } = useContext(PaymentMethodContext);

  const venmoPaymentMethod = paymentMethodForType('venmo') as PaymentMethod;
  const maybeBraintreeGateway = gatewayForPaymentMethodAndGatewayType(venmoPaymentMethod, 'braintree_blue');

  const venmo = useMemo(() => maybeBraintreeGateway && recurly.Venmo({
    braintree: {
      clientAuthorization: (maybeBraintreeGateway.credentials as BraintreeCredentials).tokenizationKey
    }
  }), [maybeBraintreeGateway]);

  useEffect(() => {
    if (!venmo) return;

    venmo.on('error', (error) => {
      setCanSubmit(true);
      if (error.cause?.code === 'FRAME_SERVICE_FRAME_CLOSED') {
        return;
      }
      onError(error);
    });

    venmo.on('cancel', () => {
      setCanSubmit(true);
    });

    venmo.on('token', (token) => {
      setCanSubmit(true);
      setPaymentMethod(venmoPaymentMethod);
      onToken(token);
    });
  }, [venmo]);

  if (!maybeBraintreeGateway) {
    return <></>;
  }

  return (
    <Button
      className="
      bg-[#008cff]
        min-w-full min-h-12 py-0 px-4 mb-2
        rounded font-bold border-transparent
        hover:brightness-95 col-span-2
      "
      onClick={(event) => {
        event.preventDefault();
        setCanSubmit(false);
        venmo.start();
      }}
      processing={!canSubmit}
    >
      <VenmoLogoImage className="mx-auto" />
    </Button>
  );
}
