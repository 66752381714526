import React, { PropsWithChildren, useEffect, useState } from 'react';

import type { Customer } from '../../services/purchase';

import { Field, SelectInput, TextInput } from '../form';
import { FLAGS } from '../../services/session';
import { useLocale } from '../../hooks/use-locale';
import { useCheckoutSession } from '../../hooks/use-checkout-session';

export type Address = {
  firstName?: string;
  lastName?: string;
  company?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
  phone?: string;
  vatNumber?: string;
  taxIdentifier?: string;
  taxIdentifierType?: 'cpf' | 'cnpj' | 'cuit';
};

type AutoCompletePrefix = 'shipping' | 'billing';

type AddressArgs = {
  autoCompletePrefix?: AutoCompletePrefix;
  namePrefix?: string;
  onChange?: (_address: Address) => void;
  required?: string[];
  value?: Address;
};

const nameFor = (name: string, prefix?: string) => prefix ? `${prefix}[${name}]` : name;

const NAME = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  COMPANY: 'company',
  ADDRESS_1: 'address1',
  ADDRESS_2: 'address2',
  CITY: 'city',
  STATE: 'state',
  COUNTRY: 'country',
  POSTAL_CODE: 'postal_code',
  PHONE: 'phone',
  VAT_NUMBER: 'vat_number',
  TAX_IDENTIFIER: 'tax_identifier',
  TAX_IDENTIFIER_TYPE: 'tax_identifier_type'
};
export const ADDRESS_FIELD_NAME = NAME;

const autoCompleteValueFor = (name: string, prefix?: AutoCompletePrefix) => prefix ? `${prefix} ${name}` : name;

export function FullAddress ({
  autoCompletePrefix,
  namePrefix = '',
  onChange = () => {},
  required = [],
  value
}: AddressArgs) {
  const { t } = useLocale();
  const [city, setCity] = useState(value?.city || '');
  const [state, setState] = useState(value?.state || '');

  useEffect(() => setCity(value?.city || ''), [value?.city]);
  useEffect(() => setState(value?.state || ''), [value?.state]);

  return (
    <StreetAddress {...{ autoCompletePrefix, namePrefix, onChange, required, value }}>
      <Field
        label={t('address.city')}
        name={nameFor(NAME.CITY, namePrefix)}
        className="col-span-2"
        required={required.includes(NAME.CITY)}
      >
        <TextInput
          autoComplete={autoCompleteValueFor('address-level2', autoCompletePrefix)}
          onBlur={() => onChange({ city })}
          onChange={event => setCity(event.target.value)}
          value={city}
        />
      </Field>
      <Field
        label={t('address.state')}
        name={nameFor(NAME.STATE, namePrefix)}
        required={required.includes(NAME.STATE)}
        className="col-span-2 xs:col-span-1"
      >
        <TextInput
          autoComplete={autoCompleteValueFor('address-level1', autoCompletePrefix)}
          onBlur={() => onChange({ state })}
          onChange={event => setState(event.target.value)}
          value={state}
        />
      </Field>
    </StreetAddress>
  );
}

export function StreetAddress ({
  autoCompletePrefix,
  children,
  namePrefix = '',
  onChange = () => {},
  required = [],
  value
}: AddressArgs & PropsWithChildren) {
  const { t } = useLocale();
  const [address1, setAddress1] = useState(value?.address1 || '');
  const [address2, setAddress2] = useState(value?.address2 || '');

  useEffect(() => setAddress1(value?.address1 || ''), [value?.address1]);
  useEffect(() => setAddress2(value?.address2 || ''), [value?.address2]);

  return (
    <>
      <Field
        label={t('address.address1')}
        name={nameFor(NAME.ADDRESS_1, namePrefix)}
        required={required.includes(NAME.ADDRESS_1)}
        className="col-span-2"
      >
        <TextInput
          autoComplete={autoCompleteValueFor('address-line1', autoCompletePrefix)}
          onBlur={() => onChange({ address1 })}
          onChange={event => setAddress1(event.target.value)}
          value={address1}
        />
      </Field>
      <Field
        label={t('address.address2')}
        name={nameFor(NAME.ADDRESS_2, namePrefix)}
        required={required.includes(NAME.ADDRESS_2)}
        className="col-span-2"
      >
        <TextInput
          autoComplete={autoCompleteValueFor('address-line2', autoCompletePrefix)}
          onBlur={() => onChange({ address2 })}
          onChange={event => setAddress2(event.target.value)}
          value={address2}
        />
      </Field>
      {children}
      <PostalCodeAddress {...{ namePrefix, onChange, required, value }} />
    </>
  );
}

export function PostalCodeAddress ({
  autoCompletePrefix,
  namePrefix = '',
  onChange = () => {},
  required = [],
  value
}: AddressArgs) {
  const [{ flags }] = useCheckoutSession();
  const { t } = useLocale();
  const [postalCode, setPostalCode] = useState(value?.postalCode || '');
  const [phone, setPhone] = useState(value?.phone || '');

  useEffect(() => setPostalCode(value?.postalCode || ''), [value?.postalCode]);
  useEffect(() => setPhone(value?.phone || ''), [value?.phone]);

  return (
    <>
      <Field
        label={t('address.postal-code')}
        name={nameFor(NAME.POSTAL_CODE, namePrefix)}
        required={required.includes(NAME.POSTAL_CODE)}
        className="col-span-2 xs:col-span-1"
      >
        <TextInput
          autoComplete={autoCompleteValueFor('postal-code', autoCompletePrefix)}
          onBlur={() => onChange({ postalCode })}
          onChange={event => setPostalCode(event.target.value)}
          value={postalCode}
        />
      </Field>

      {flags.includes(FLAGS.ACCEPT_PHONE_NUMBER) && (
        <Field
          label={t('address.phone-number')}
          name={nameFor(NAME.PHONE, namePrefix)}
          required={required.includes(NAME.PHONE)}
          className="col-span-2"
        >
          <TextInput
            autoComplete={autoCompleteValueFor('tel', autoCompletePrefix)}
            onBlur={() => onChange({ phone })}
            onChange={event => setPhone(event.target.value)}
            value={phone}
          />
        </Field>
      )}
    </>
  );
}

export function FirstAndLastName ({
  autoCompletePrefix,
  namePrefix = '',
  onCustomerChange = () => {},
  onAddressChange = () => {},
  customer,
  address
}: {
  autoCompletePrefix?: AutoCompletePrefix;
  namePrefix?: string;
  onCustomerChange: (_customer: Customer) => void,
  onAddressChange: (_address: Address) => void,
  customer?: Customer,
  address?: Address
}) {
  const [{ flags }] = useCheckoutSession();
  const { t } = useLocale();
  const [company, setCompany] = useState(address?.company || '');
  const [firstName, setFirstName] = useState(customer?.firstName || '');
  const [lastName, setLastName] = useState(customer?.lastName || '');
  const [vatNumber, setVatNumber] = useState(address?.vatNumber || '');

  useEffect(() => setCompany(address?.company || ''), [address?.company]);
  useEffect(() => setFirstName(customer?.firstName || ''), [customer?.firstName]);
  useEffect(() => setLastName(customer?.lastName || ''), [customer?.lastName]);
  useEffect(() => setVatNumber(address?.vatNumber || ''), [address?.vatNumber]);

  return (
    <>
      <Field
        label={t('address.first-name')}
        name={nameFor(NAME.FIRST_NAME, namePrefix)}
        className="col-span-2 xs:col-span-1"
        required
      >
        <TextInput
          autoComplete={autoCompleteValueFor('given-name', autoCompletePrefix)}
          onBlur={() => onCustomerChange({ firstName })}
          onChange={event => setFirstName(event.target.value)}
          value={firstName}
        />
      </Field>

      <Field
        label={t('address.last-name')}
        name={nameFor(NAME.LAST_NAME, namePrefix)}
        className="col-span-2 xs:col-span-1"
        required
      >
        <TextInput
          autoComplete={autoCompleteValueFor('family-name', autoCompletePrefix)}
          onBlur={() => onCustomerChange({ lastName })}
          onChange={event => setLastName(event.target.value)}
          value={lastName}
        />
      </Field>

      {flags.includes(FLAGS.ACCEPT_COMPANY_NAME) && (
        <Field
          label={t('address.company-name')}
          name={nameFor(NAME.COMPANY, namePrefix)}
          className="col-span-2"
        >
          <TextInput
            autoComplete={autoCompleteValueFor('organization', autoCompletePrefix)}
            onBlur={() => onAddressChange({ company })}
            onChange={event => setCompany(event.target.value)}
            value={company}
          />
        </Field>
      )}

      {flags.includes(FLAGS.ACCEPT_VAT_NUMBER) && (
        <Field
          label={t('address.vat-number')}
          name={nameFor(NAME.VAT_NUMBER, namePrefix)}
          className="col-span-2"
        >
          <TextInput
            onBlur={() => onAddressChange({ vatNumber })}
            onChange={event => setVatNumber(event.target.value)}
            value={vatNumber}
          />
        </Field>
      )}
    </>
  );
}

export function TaxIdentifier ({
  address,
  onAddressChange = () => {},
}: {
  address: Address,
  onAddressChange: (_address: Address) => void
}) {
  const { t } = useLocale();
  const [taxIdentifier, setTaxIdentifier] = useState(address?.taxIdentifier || '');
  const [taxIdentifierType, setTaxIdentifierType] = useState(address?.taxIdentifierType || '');

  useEffect(() => setTaxIdentifier(address?.taxIdentifier || ''), [address?.taxIdentifier]);
  useEffect(() => setTaxIdentifierType(address?.taxIdentifierType || ''), [address?.taxIdentifierType]);

  return (
    <>
      <Field
        label={t('address.tax-identifier')}
        name={NAME.TAX_IDENTIFIER}
        className="col-span-2 xs:col-span-1"
      >
        <TextInput
          onBlur={() => onAddressChange({ taxIdentifier })}
          onChange={event => setTaxIdentifier(event.target.value)}
          value={taxIdentifier}
        />
      </Field>

      <Field
        label={t('address.tax-identifier-type')}
        name={NAME.TAX_IDENTIFIER_TYPE}
        className="col-span-2 xs:col-span-1"
      >
        <SelectInput
          onChange={event => {
            const value = event.target.value;
            setTaxIdentifierType(value);
            onAddressChange({ taxIdentifierType: value });
          }}
          options={[
            { name: 'CPF', value: 'cpf' },
            { name: 'CNPJ', value: 'cnpj' },
            { name: 'CUIT', value: 'cuit' }
          ]}
          value={taxIdentifierType}
        />
      </Field>
    </>
  );
}

export function ReflectiveAddress ({
  namePrefix = '',
  address
}: {
  namePrefix?: string;
  address?: Address;
}) {
  if (!address) return <></>;

  return [
    [NAME.FIRST_NAME, address.firstName],
    [NAME.LAST_NAME, address.lastName],
    [NAME.COMPANY, address.company],
    [NAME.ADDRESS_1, address.address1],
    [NAME.ADDRESS_2, address.address2],
    [NAME.CITY, address.city],
    [NAME.STATE, address.state],
    [NAME.COUNTRY, address.country],
    [NAME.POSTAL_CODE, address.postalCode],
    [NAME.PHONE, address.phone],
    [NAME.VAT_NUMBER, address.vatNumber]
  ].map(([name = '', from = ''], index) => {
    if (!from) return;
    const fieldName = nameFor(name, namePrefix);
    return (
      <input
        type="hidden"
        key={index}
        name={fieldName}
        data-recurly={fieldName}
        value={from}
      />
    );
  });
}
