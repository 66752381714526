import React, { useContext, useEffect, useRef } from 'react';
import { useRecurly } from '@recurly/react-recurly';

import { AdyenCredentials } from '@/services/session';
import { PaymentMethodSelectorContext } from '../payment-methods';
import { PaymentMethodOption } from './option';
import { useLocale } from '@/hooks/use-locale';
import { useCheckoutSession } from '@/hooks/use-checkout-session';
import { usePaymentMethodContext } from '@/hooks/use-payment-method-context';

export function IdealPaymentMethodOption () {
  const { t, locale } = useLocale();
  const recurly = useRecurly();
  const targetRef = useRef(document.createElement('div'));
  const [{ cart: { currency } }] = useCheckoutSession();
  const { setError } = useContext(PaymentMethodSelectorContext);
  const {
    billingAddress,
    paymentMethod,
    paymentMethodForType,
    pricing,
    setBillingInfoTokenCreator
  } = usePaymentMethodContext();
  const idealPaymentMethod = paymentMethodForType('ideal');
  const adyenGateway = idealPaymentMethod?.gateways.find(({ type }) => type === 'adyen');

  useEffect(() => {
    if (!adyenGateway) return;
    if (currency.code !== 'EUR') return;
    if (!targetRef?.current) return;
    if (paymentMethod?.type !== idealPaymentMethod?.type) return;

    const ideal = recurly.AlternativePaymentMethods({
      allowedPaymentMethods: ['ideal'],
      blockedPaymentMethods: [],
      containerSelector: targetRef?.current,
      amount: pricing?.price?.now?.total,
      currency: currency.code,
      countryCode: 'NL',
      locale: locale.baseName,
      channel: 'Web',
      adyen: {
        publicKey: (adyenGateway.credentials as AdyenCredentials).clientKey,
        showPayButton: false,
        componentConfig: {}
      },
      returnURL: window.location.href
    });

    ideal.on('error', (error) => setError(error));

    ideal.start();

    setBillingInfoTokenCreator(() => (form, done) => {
      ideal.once('token', (token) => done(undefined, token));
      ideal.submit({ billingAddress });
    });
  }, [currency, targetRef.current, paymentMethod, pricing]);

  if (currency.code !== 'EUR') return <></>;

  return (
    <PaymentMethodOption title={t('payment-method.ideal.name')} type="ideal">
      <div ref={targetRef}></div>
    </PaymentMethodOption>
  );
}
