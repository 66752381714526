import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useEffect } from 'react';
import { RecurlyError } from '@recurly/recurly-js';

import { AchPaymentMethodOption } from './payment-methods/ach';
import { BacsPaymentMethodOption } from './payment-methods/bacs';
import { BecsPaymentMethodOption } from './payment-methods/becs';
import { BoletoPaymentMethodOption } from './payment-methods/boleto';
import { CreditCardPaymentMethodOption } from './payment-methods/credit-card';
import { FieldGroup } from '../form';
import { FormError } from '@/services/purchase';
import { IdealPaymentMethodOption } from './payment-methods/ideal';
import { PaymentGateway, PaymentMethod, PaymentMethodType } from '@/services/session';
import { SepaPaymentMethodOption } from './payment-methods/sepa';
import { SofortPaymentMethodOption } from './payment-methods/sofort';
import { useLocale } from '@/hooks/use-locale';
import { ExpressCheckout } from './express-checkout';
import { usePaymentMethodContext } from '@/hooks/use-payment-method-context';
import { useCartContext } from '@/hooks/use-cart-context';

export type RecurlyErrorHandler = (_error: RecurlyError) => void;


export const gatewayForPaymentMethodAndGatewayType = (
  paymentMethod: PaymentMethod,
  search: PaymentGateway['type']
) => paymentMethod.gateways.find(({ type }) => type === search);

export function PaymentInterface ({
  children,
  setError
} : PropsWithChildren & {
  setError: Dispatch<SetStateAction<FormError | undefined>>;
}) {
  const {
    usedExpressCheckout,
    setBillingInfoToken,
  } = usePaymentMethodContext();

  const { requiresBillingInfo } = useCartContext();

  if (!requiresBillingInfo) {
    return children;
  }

  return (
    <>
      <ExpressCheckout
        onError={(error) => setError(new FormError(error))}
        onToken={(token) => setBillingInfoToken(token)}
      />
      {children}
      {!usedExpressCheckout && (
        <PaymentMethodSelector setError={error => setError(new FormError(error))} />
      )}
    </>
  );
}

export const EXPRESS_CHECKOUT_PAYMENT_METHOD_TYPES = [
  'paypal',
  'venmo',
  'apple_pay',
  'amazon'
];

export const PaymentMethodSelectorContext = createContext<{
  optionCount: number;
  setError: RecurlyErrorHandler;
}>({
  optionCount: 0,
  setError: () => {}
});

const ORDERED_PAYMENT_METHOD_SELECTOR_TYPES: PaymentMethodType[] = [
  'credit_card',
  'ach',
  'bacs',
  'becs',
  'sepa',
  'boleto',
  'ideal',
  'sofort'
];

export function PaymentMethodSelector ({ setError }: { setError: RecurlyErrorHandler }) {
  const { t } = useLocale();
  const {
    acceptPaymentMethodType,
    acceptedPaymentMethods,
    paymentMethod,
    paymentMethodForType,
    setPaymentMethod
  } = usePaymentMethodContext();

  const optionCount = acceptedPaymentMethods.filter(({ type }) => (
    ORDERED_PAYMENT_METHOD_SELECTOR_TYPES.includes(type))
  ).length;

  // Set the default payment method to the first available of our global ordering
  useEffect(() => {
    if (paymentMethod) return;

    const firstPaymentMethod = ORDERED_PAYMENT_METHOD_SELECTOR_TYPES.find(acceptPaymentMethodType);
    if (firstPaymentMethod) {
      setPaymentMethod(paymentMethodForType(firstPaymentMethod));
    }
  }, [acceptedPaymentMethods]);

  if (optionCount === 0) return;

  return (
    <PaymentMethodSelectorContext.Provider value={{ optionCount, setError }}>
      <FieldGroup title={t('payment-info')}>
        {acceptPaymentMethodType('credit_card') && <CreditCardPaymentMethodOption />}
        {acceptPaymentMethodType('ach') && <AchPaymentMethodOption />}
        {acceptPaymentMethodType('bacs') && <BacsPaymentMethodOption />}
        {acceptPaymentMethodType('becs') && <BecsPaymentMethodOption />}
        {acceptPaymentMethodType('sepa') && <SepaPaymentMethodOption />}
        {acceptPaymentMethodType('boleto') && <BoletoPaymentMethodOption />}
        {acceptPaymentMethodType('ideal') && <IdealPaymentMethodOption />}
        {acceptPaymentMethodType('sofort') && <SofortPaymentMethodOption />}
      </FieldGroup>
    </PaymentMethodSelectorContext.Provider>
  );
}
