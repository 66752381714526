import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { useRecurly } from '@recurly/react-recurly';
import { Helmet } from 'react-helmet-async';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSubmit } from 'react-router-dom';

import { PaymentInterface } from './checkout/payment-methods';
import { AddressForm } from './checkout/address-form';
import { browserLocale } from '@/services/locale';
import { Button, CheckboxInput, Field, FieldGroup, FormContext, TextInput } from './form';
import { Cart } from  './checkout/cart';
import {
  Customer,
  PurchaseCreator,
  FormError,
  ThreeDSecureRequiredError,
  ReCaptchaRequiredError
} from '@/services/purchase';
import { FLAGS } from '@/services/session';
import { RecurlyWithInternals } from '@/types/recurly';
import { Theme } from './theme';
import { ThreeDSecureModal } from './checkout/three-d-secure-modal';
import { useCheckoutSession } from '@/hooks/use-checkout-session';
import { useLocale } from '@/hooks/use-locale';
import { CART_EMPTINESS_STATE } from '@/contexts/cart-context';
import { CartInternalContextProvider } from '@/contexts/cart-internal-context';
import { FirstAndLastName } from './checkout/first-and-last-name';
import { TaxIdentifier } from './checkout/tax-identifier';
import { ReflectiveAddress } from './checkout/reflective-address';
import { useCartContext } from '@/hooks/use-cart-context';
import { usePaymentMethodContext } from '@/hooks/use-payment-method-context';

const SHOW_TAX_IDENTIFIER_FOR_COUNTRY_CODES = ['BR', 'AR'];
const preferredCountry = browserLocale().region;

export function Checkout () {
  const recurly = useRecurly() as RecurlyWithInternals;
  const [billingAddressSameAsShipping, setBillingAddressSameAsShipping] = useState(false);
  const [checkoutSession, _setCheckoutSession] = useCheckoutSession();
  const [customer, setCustomer] = useState<Customer>({});
  const [canSubmit, setCanSubmit] = useState(true);
  const [error, setError] = useState<FormError>();
  const [policiesAcceptance, setPoliciesAcceptance] = useState(false);
  const [reCaptchaClientKey, setReCaptchaClientKey] = useState('');
  const [recaptchaResult, setRecaptchaResult] = useState('');
  const [required, setRequired] = useState<string[]>([]);
  const [showBillingAddressFields, setShowBillingAddressFields] = useState(false);
  const [showTaxIdentifierField, setShowTaxIdentifierField] = useState(false);
  const [threeDSecureActionTokenId, setThreeDSecureActionTokenId] = useState<string>();
  const [threeDSecureActionResultTokenId, setThreeDSecureActionResultTokenId] = useState<string>();
  const errorAlertRef = useRef(document.createElement('div'));
  const formRef = useRef(document.createElement('form'));
  const submit = useSubmit();
  const { t } = useLocale();

  const {
    cartEmptiness,
    requiresBillingInfo,
    billingAddress,
    setBillingAddress,
    shippingAddress,
    setShippingAddress,
  } = useCartContext();

  const {
    billingInfoTokenCreator,
    billingInfoToken,
    usedExpressCheckout,
  } = usePaymentMethodContext();

  const {
    id: checkoutSessionTokenId,
    cart: { currency },
    flags,
    logoUrl,
    iconUrl,
    cancelUrl,
    confirmUrl,
    paymentMethods,
    privacyPolicyUrl,
    site: {
      addressRequirement,
      name: siteName
    },
    tosUrl
  } = checkoutSession;

  const purchaseCreator = new PurchaseCreator(
    billingInfoToken,
    billingInfoTokenCreator,
    checkoutSessionTokenId,
    formRef,
    recurly
  );

  const acceptShippingAddress = flags.includes(FLAGS.ACCEPT_SHIPPING_ADDRESS);

  // Asserts address requirement validations client-side
  useEffect(() => {
    if (addressRequirement === 'none') return;

    if (addressRequirement === 'zip') {
      setRequired(['postal_code']);
    } else if (addressRequirement === 'streetzip') {
      setRequired(['address1', 'postal_code']);
    } else if (addressRequirement === 'full') {
      setRequired(['address1', 'city', 'state', 'country', 'postal_code']);
    }
  }, [addressRequirement]);

  // Configures required fields
  useEffect(() => {
    recurly?.configure({ required, hostname: recurly.config.hostname });
  }, [required]);

  // Hides billing address when it's not required or when Express Checkout has been used
  useEffect(() => {
    if (!requiresBillingInfo) {
      setShowBillingAddressFields(false);
    } else if (usedExpressCheckout) {
      setShowBillingAddressFields(false);
    } else {
      setShowBillingAddressFields(addressRequirement !== 'none');
    }
  }, [currency, paymentMethods, requiresBillingInfo, usedExpressCheckout]);

  // Sets billing address from shipping address
  useEffect(() => {
    if (!billingAddressSameAsShipping) return;
    if (!shippingAddress) return;
    setBillingAddress(shippingAddress);
  }, [billingAddressSameAsShipping, shippingAddress]);

  // Sets a default shipping country
  useEffect(() => {
    if (!acceptShippingAddress) return;
    if (shippingAddress?.country) return;
    setShippingAddress({ ...shippingAddress, country: preferredCountry });
  }, [acceptShippingAddress]);

  // Translates billing address errors to shipping address when billing address is copied from shipping.
  // We do this because billing address is client- and server-validated and shipping address is only server-validated
  useEffect(() => {
    if (!error) return;
    if (!billingAddressSameAsShipping) return;
    if (!error.addressErrorsPresent()) return;
    setError(FormError.withDuplicatedAddressFields(error, name => `shipping[address][${name}]`));
  }, [billingAddressSameAsShipping, error]);

  useEffect(() => {
    setShowTaxIdentifierField(
      flags.includes(FLAGS.ACCEPT_TAX_IDENTIFIER)
      && SHOW_TAX_IDENTIFIER_FOR_COUNTRY_CODES.includes(billingAddress?.country || '')
    );
  }, [flags, billingAddress]);

  const handleSubmit = async (e?: FormEvent) => {
    e?.preventDefault();
    setError(undefined);

    setCanSubmit(false);

    try {
      const purchase = await purchaseCreator.create(
        currency,
        customer,
        shippingAddress,
        threeDSecureActionResultTokenId,
        recaptchaResult,
        policiesAcceptance,
        requiresBillingInfo
      );

      if (confirmUrl) {
        window.location.replace(confirmUrl);
      } else {
        submit({
          checkoutSession: {
            ...checkoutSession,
            purchases: [purchase]
          }
        }, {
          action: `/order/${checkoutSessionTokenId}`,
          method: 'post',
          encType: 'application/json'
        });
      }
    } catch (err) {
      if (err instanceof ThreeDSecureRequiredError) {
        setThreeDSecureActionResultTokenId(undefined);
        setThreeDSecureActionTokenId(err.threeDSecureActionTokenId);
      } else if (err instanceof ReCaptchaRequiredError) {
        setError(err);
        setReCaptchaClientKey(err.reCaptchaClientKey);
        setCanSubmit(true);
      } else if (err instanceof FormError) {
        setError(err);
        setCanSubmit(true);
      } else {
        setCanSubmit(true);
        throw err;
      }
    }
  };

  useEffect(() => {
    if (!threeDSecureActionResultTokenId) return;
    handleSubmit();
  }, [threeDSecureActionResultTokenId]);

  useEffect(() => {
    const ref = errorAlertRef?.current;
    if (!ref) return;
    if ('scrollIntoView' in ref) ref.scrollIntoView({ behavior: 'smooth' });
  }, [error, errorAlertRef]);

  return (
    <FormContext.Provider value={{ error, handleSubmit }}>
      {iconUrl && <Helmet><link rel="icon" href={iconUrl} /></Helmet>}
      <Theme />
      <form onSubmit={handleSubmit} ref={formRef}>
        <div className="font-sans grid gap-x-8 min-w-80 px-4 lg:px-8 grid-cols-1 lg:grid-cols-[auto_auto_min-content]">
          <header className="w-full max-w-lg mx-auto mt-6 mb-10 order-1 lg:col-span-2 lg:mt-8">
            {logoUrl
              ? <img className="max-h-16 max-w-auto" src={logoUrl} alt={siteName} />
              : (
                <div className="text-3xl font-semibold">
                  {siteName}
                </div>
              )
            }

            {cancelUrl && (
              <div className="lg:max-w-lg mx-auto lg:mx-0 lg:pr-8 mt-4">
                <a href={cancelUrl}>← {t('back')}</a>
              </div>
            )}
          </header>

          <section className="order-2 lg:order-4">
            <CartInternalContextProvider>
              <Cart />
            </CartInternalContextProvider>
          </section>

          {cartEmptiness === CART_EMPTINESS_STATE.NO_ITEMS && (
            <div
              className="
                xs:mx-auto max-w-lg order-3 mb-8 p-5 rounded-lg bg-checkout-accent
                h-auto text-center py-8 text-xl font-normal text-checkout-subtle
                lg:col-span-2
              "
            >
              {t('cart.empty')}
            </div>
          )}

          {cartEmptiness !== CART_EMPTINESS_STATE.NO_ITEMS && (
            <section className="xs:mx-auto w-full max-w-lg order-3 lg:col-span-2">
              {error?.base && (
                <div
                  className="
                    col-span-2
                    p-2
                    mb-4
                    mx-auto
                    lg:max-w-lg
                    border-2
                    rounded
                    bg-red-50
                    border-red-400
                    text-red-400
                    dark:border-0
                    dark:bg-red-300
                    dark:text-red-700
                  "
                  ref={errorAlertRef}
                  style={{ scrollMargin: '2rem' }}
                >
                  {error.base.fullMessages}
                </div>
              )}

              <div className="grid grid-cols-2 gap-4">
                <FieldGroup title={t('contact-info')}>
                  <Field
                    label={t('email-address')}
                    className="col-span-2"
                    name="email_address"
                    required
                  >
                    <TextInput
                      autoComplete="email"
                      onChange={event => setCustomer({ ...customer, emailAddress: event.target.value }) }
                    />
                  </Field>

                  {addressRequirement === 'none' && (
                    <FirstAndLastName
                      autoCompletePrefix="billing"
                      onCustomerChange={values => setCustomer({ ...customer, ...values })}
                      onAddressChange={values => setBillingAddress({ ...billingAddress, ...values })}
                    />
                  )}
                </FieldGroup>

                <PaymentInterface
                  setError={setError}
                >
                  {acceptShippingAddress && (
                    <FieldGroup title={t('shipping-address')}>
                      <AddressForm
                        address={shippingAddress}
                        addressRequirement='full'
                        customer={shippingAddress}
                        namePrefix='shipping[address]'
                        required={required}
                        setAddress={setShippingAddress}
                        setCustomer={setShippingAddress}
                        showTaxIdentifierField={false}
                      />
                    </FieldGroup>
                  )}
                </PaymentInterface>

                {showBillingAddressFields  && (
                  <FieldGroup title={t('billing-address')}>
                    {acceptShippingAddress && (
                      <Field
                        name="billing-same-as-shipping"
                        className="col-span-2"
                      >
                        <CheckboxInput
                          checked={billingAddressSameAsShipping}
                          onChange={event => setBillingAddressSameAsShipping(event.target.checked)}
                        >
                          {t('billing-address-same-as-shipping')}
                        </CheckboxInput>
                      </Field>
                    )}

                    {billingAddressSameAsShipping
                      ? (
                        <>
                          <ReflectiveAddress address={shippingAddress} />
                          {showTaxIdentifierField && (
                            <TaxIdentifier
                              address={billingAddress}
                              onAddressChange={values => setBillingAddress({ ...billingAddress, ...values })}
                            />
                          )}
                        </>
                      )
                      : (
                        <AddressForm
                          address={billingAddress}
                          addressRequirement={addressRequirement}
                          customer={customer}
                          required={required}
                          setAddress={setBillingAddress}
                          setCustomer={setCustomer}
                          showTaxIdentifierField={showTaxIdentifierField}
                        />
                      )}
                  </FieldGroup>
                )}

                {(privacyPolicyUrl || tosUrl) && (
                  <Field
                    name="policies_acceptance"
                    className="col-span-2 mt-4"
                  >
                    <CheckboxInput
                      checked={policiesAcceptance}
                      onChange={event => setPoliciesAcceptance(event.target.checked)}
                    >
                      {t('legal.accept')}
                      &nbsp;
                      {t.toSentence([
                        (privacyPolicyUrl && (
                          <a href={privacyPolicyUrl} target="_blank" rel="noopener noreferrer">
                            {t('legal.privacy-policy')}
                          </a>
                        )),
                        (tosUrl && (
                          <a href={tosUrl} target="_blank" rel="noopener noreferrer">
                            {t('legal.terms-of-service')}
                          </a>
                        ))
                      ].filter(Boolean))}
                    </CheckboxInput>
                  </Field>
                )}

                {reCaptchaClientKey && (
                  <div className="mt-6 col-start-1">
                    <ReCAPTCHA
                      sitekey={reCaptchaClientKey}
                      onChange={(result) => setRecaptchaResult(result)}
                    />
                  </div>
                )}

                <Button
                  type="submit"
                  className="col-span-2 my-6"
                  processing={!canSubmit}
                  disabled={cartEmptiness !== CART_EMPTINESS_STATE.NOT_EMPTY}
                >
                  {requiresBillingInfo ? t('submit') : t('subscribe')}
                </Button>
              </div>
            </section>
          )}
        </div>
      </form>

      {threeDSecureActionTokenId && (
        <ThreeDSecureModal
          actionTokenId={threeDSecureActionTokenId}
          onCancel={() => {
            setThreeDSecureActionTokenId(undefined);
            setCanSubmit(true);
          }}
          onError={error => {
            setError(new FormError(error));
            setThreeDSecureActionTokenId(undefined);
            setCanSubmit(true);
          }}
          onToken={token => {
            setThreeDSecureActionResultTokenId(token.id);
            setThreeDSecureActionTokenId(undefined);
          }}
        />
      )}
    </FormContext.Provider>
  );
}
