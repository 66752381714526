import React from 'react';
import { FieldGroup } from '../form';
import { PayPalButton } from './payment-methods/paypal';
import { VenmoButton } from './payment-methods/venmo';
import { ApplePayButton } from './payment-methods/apple-pay';
import { AmazonPayButton } from './payment-methods/amazon';
import { RecurlyErrorHandler } from './payment-methods';
import { useLocale } from '@/hooks/use-locale';
import { TokenPayload } from '@recurly/recurly-js';
import { PaymentMethodSummary } from './payment-method-summary';
import { usePaymentMethodContext } from '@/hooks/use-payment-method-context';

export type ExpressCheckoutProps = {
  onError: RecurlyErrorHandler;
  onToken: (_token: TokenPayload | undefined) => void;
}

export function ExpressCheckout (props: ExpressCheckoutProps) {
  const { t } = useLocale();
  const { acceptPaymentMethodType } = usePaymentMethodContext();


  const environmentSupportsApplePay = () => window?.ApplePaySession?.canMakePayments();
  const acceptPayPal = acceptPaymentMethodType('paypal');
  const acceptVenmo = acceptPaymentMethodType('venmo');
  const acceptApplePay = acceptPaymentMethodType('apple_pay') && environmentSupportsApplePay();
  const acceptAmazonPay = acceptPaymentMethodType('amazon');

  const {
    usedExpressCheckout
  } = usePaymentMethodContext();

  if (!(acceptPayPal || acceptVenmo || acceptApplePay || acceptAmazonPay)) {
    return <></>;
  }

  if (usedExpressCheckout) return <PaymentMethodSummary onClearPaymentMethod={() => props.onToken(undefined)} />;

  return (
    <FieldGroup title={t('express-checkout')}>
      <div className="col-span-2 space-y-2">
        {acceptPayPal && (<PayPalButton {...props} />)}
        {acceptVenmo && (<VenmoButton {...props} />)}
        {acceptApplePay && (<ApplePayButton {...props} />)}
        {acceptAmazonPay && (<AmazonPayButton {...props} />)}
      </div>
    </FieldGroup>
  );
}
