import React from 'react';
import { CssColor } from '../../types/html';
import { currentForegroundRgb } from '../theme';

export function ChevronImage ({
  className,
  color = currentForegroundRgb()
}: {
  className?: string;
  color?: CssColor;
}) {
  return (
    <svg focusable="false" viewBox="0 0 12 12" className={className}>
      <path d="M10.193 3.97a.75.75 0 0 1 1.062 1.062L6.53 9.756a.75.75 0 0 1-1.06 0L.745 5.032A.75.75 0 0 1 1.807 3.97L6 8.163l4.193-4.193z" fill={color}></path>
    </svg>
  );
}
