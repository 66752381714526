import React, { useContext, useEffect, useRef } from 'react';
import { useRecurly } from '@recurly/react-recurly';

import { AdyenCredentials } from '@/services/session';
import { PaymentMethodSelectorContext } from '../payment-methods';
import { PaymentMethodOption } from './option';
import { useLocale } from '@/hooks/use-locale';
import { useCheckoutSession } from '@/hooks/use-checkout-session';
import { usePaymentMethodContext } from '@/hooks/use-payment-method-context';

export function BoletoPaymentMethodOption () {
  const { t, locale } = useLocale();
  const recurly = useRecurly();
  const targetRef = useRef(document.createElement('div'));
  const [{ cart: { currency } }] = useCheckoutSession();
  const { setError } = useContext(PaymentMethodSelectorContext);
  const {
    billingAddress,
    paymentMethod,
    paymentMethodForType,
    pricing,
    setBillingInfoTokenCreator
  } = usePaymentMethodContext();
  const boletoPaymentMethod = paymentMethodForType('boleto');
  const adyenGateway = boletoPaymentMethod?.gateways.find(({ type }) => type === 'adyen');

  useEffect(() => {
    if (!adyenGateway) return;
    if (currency.code !== 'BRL') return;
    if (!targetRef?.current) return;
    if (paymentMethod?.type !== boletoPaymentMethod?.type) return;

    const boleto = recurly.AlternativePaymentMethods({
      allowedPaymentMethods: ['boleto'],
      blockedPaymentMethods: [],
      containerSelector: targetRef?.current,
      amount: pricing?.price?.now?.total,
      currency: currency.code,
      countryCode: 'BR',
      locale: locale.baseName,
      channel: 'Web',
      adyen: {
        publicKey: (adyenGateway.credentials as AdyenCredentials).clientKey,
        showPayButton: false,
        componentConfig: {}
      },
      returnURL: window.location.href
    });

    boleto.on('error', (error) => setError(error));

    boleto.start();

    setBillingInfoTokenCreator(() => (form, done) => {
      boleto.once('token', (token) => done(undefined, token));
      boleto.submit({ billingAddress });
    });
  }, [currency, targetRef.current, paymentMethod, pricing]);

  if (currency.code !== 'BRL') return <></>;

  return (
    <PaymentMethodOption title={t('payment-method.boleto.name')} type="boleto">
      <div ref={targetRef}></div>
    </PaymentMethodOption>
  );
}
