import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';
import { PaymentMethod, PaymentMethodType } from '@/services/session';
import { CheckoutAddress } from '@/types/checkout-address';
import { CheckoutPricingInstance, TokenPayload } from '@recurly/recurly-js';
import { EXPRESS_CHECKOUT_PAYMENT_METHOD_TYPES } from '@/components/checkout/payment-methods';
import { useCheckoutSession } from '@/hooks/use-checkout-session';
import { useCheckoutPricing } from '@recurly/react-recurly';
import { useCartContext } from '@/hooks/use-cart-context';

export type PaymentMethodContextProps = {
  acceptedPaymentMethods: PaymentMethod[],
  acceptPaymentMethodType(_type: PaymentMethodType | PaymentMethodType[]): boolean;
  billingAddress?: CheckoutAddress;
  paymentMethod?: PaymentMethod;
  paymentMethodForType(_type?: PaymentMethodType): PaymentMethod | undefined;
  pricing?: CheckoutPricingInstance;
  setBillingAddress: Dispatch<SetStateAction<CheckoutAddress>>;
  billingInfoTokenCreator: BillingInfoTokenCreatorFunc | undefined,
  setBillingInfoTokenCreator: Dispatch<SetStateAction<BillingInfoTokenCreatorFunc | undefined>>;
  setPaymentMethod: Dispatch<SetStateAction<PaymentMethod | undefined>>;
  setShippingAddress: Dispatch<SetStateAction<CheckoutAddress | undefined>>;
  shippingAddress?: CheckoutAddress;
  usedExpressCheckout: boolean;
  billingInfoToken: TokenPayload | undefined;
  setBillingInfoToken: Dispatch<SetStateAction<TokenPayload | undefined>>;
}

export type BillingInfoTokenCreatorFunc = () => TokenPayload;

export const PaymentMethodContext = createContext<PaymentMethodContextProps | null>(null);

export function PaymentMethodProvider ({ children }: PropsWithChildren): React.JSX.Element {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [billingInfoTokenCreator, setBillingInfoTokenCreator] = useState<BillingInfoTokenCreatorFunc>();
  const [billingInfoToken, setBillingInfoToken] = useState<TokenPayload>();

  const {
    billingAddress,
    setBillingAddress,
    setShippingAddress,
  } = useCartContext();

  const [{
    cart: { currency },
    paymentMethods
  }] = useCheckoutSession();

  const [
    _useCheckoutPricingState,
    _setCheckoutPricing,
    pricing
  ] = useCheckoutPricing({});


  const paymentMethodSupportsCurrency = (pMethod?: PaymentMethod) => (
    pMethod?.currencies.some(({ code }) => code === currency?.code)
  );
  const acceptedPaymentMethods = paymentMethods.filter(paymentMethodSupportsCurrency);

  const paymentMethodForType = (search?: PaymentMethodType) => paymentMethods.find(({ type }) => search === type);
  const acceptPaymentMethodType = (types: PaymentMethodType | PaymentMethodType[]) => (
    (new Array(types)).flat().some(
      type => acceptedPaymentMethods.includes(paymentMethodForType(type as PaymentMethodType) as PaymentMethod)
    )
  );

  return (
    <PaymentMethodContext.Provider value={{
      acceptedPaymentMethods,
      acceptPaymentMethodType,
      billingAddress,
      setBillingAddress,
      setShippingAddress,
      billingInfoTokenCreator,
      setBillingInfoTokenCreator,
      paymentMethod,
      setPaymentMethod: (pMethod) => {
        setBillingInfoTokenCreator(undefined);
        setPaymentMethod(pMethod);
      },
      paymentMethodForType,
      pricing,
      usedExpressCheckout: EXPRESS_CHECKOUT_PAYMENT_METHOD_TYPES.includes(paymentMethod?.type || ''),
      billingInfoToken,
      setBillingInfoToken,
    }}>
      {children}
    </PaymentMethodContext.Provider>
  );
}
