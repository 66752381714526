import { CartContextProvider } from '@/contexts/cart-context';
import React from 'react';
import { Checkout } from './checkout';
import { PaymentMethodProvider } from '@/contexts/payment-method-context';

export function CheckoutWrapper (): React.JSX.Element {
  return (
    <CartContextProvider>
      <PaymentMethodProvider>
        <Checkout />
      </PaymentMethodProvider>
    </CartContextProvider>
  );
}
