import React, { useContext, useState } from 'react';
import { CardElement } from '@recurly/react-recurly';

import { currentForegroundRgb } from '../../theme';
import { PaymentMethodOption } from './option';
import { Field, FormContext, SelectInput, SelectInputOption } from '../../form';
import { useLocale } from '../../../hooks/use-locale';

export function CreditCardPaymentMethodOption () {
  const { error, handleSubmit } = useContext(FormContext);
  const { t } = useLocale();
  const [cardNetworkPreferenceOptions, setCardNetworkPreferenceOptions] = useState<SelectInputOption[]>([]);

  const handleChange = ({ supportedBrands }: { supportedBrands: string[] }) => {
    if (!(supportedBrands?.length > 0)) return;
    setCardNetworkPreferenceOptions(supportedBrands.map((value: string) => ({
      name: t(`payment-method.credit-card.brand.${value === 'mastercard' ? 'master' : value}`),
      value
    })));
  };

  return (
    <PaymentMethodOption title={t('payment-method.credit-card.name')} type="credit_card">
      <Field
        label={t('payment-method.credit-card.label')}
        className="col-span-2"
        name="card"
        required
      >
        <div
          className={`
            my-2 h-10
            rounded-md py-2 px-2 ring-1 text-sm shadow-sm
            ${error?.card && 'ring-red-400' || 'ring-slate-400'}
          `}
        >
          <CardElement
            style={{
              fontColor: currentForegroundRgb(),
              placeholder: {
                content: {
                  number: t('payment-method.credit-card.number'),
                  expiry: t('payment-method.credit-card.expiry'),
                  cvv: t('payment-method.credit-card.cvv')
                }
              }
            }}
            onChange={state => handleChange(state)}
            onSubmit={() => handleSubmit()}
          />
        </div>
      </Field>
      {cardNetworkPreferenceOptions.length > 0 && (
        <Field name="card_network_preference">
          <SelectInput options={cardNetworkPreferenceOptions} />
        </Field>
      )}
    </PaymentMethodOption>
  );
}
